<template>
	<div id="schedule-info" v-cloak>
		<div class="m-subheader ">
			<div class="d-flex align-items-center">
				<div class="mr-auto">
					<h3 class="m-subheader__title m-subheader__title--separator">Cronograma</h3>
					<ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
						<li class="m-nav__item m-nav__item--home">
							<router-link :to="{name: 'home'}" class="m-nav__link m-nav__link--icon">
								<i class="m-nav__link-icon la la-home"></i>
							</router-link>
						</li>
						<li class="m-nav__separator">-</li>
						<li class="m-nav__item">
							<router-link :to="{name: 'campaign.schedules'}" class="m-nav__link">
								<span class="m-nav__link-text">Gestão de Cronogramas</span>
							</router-link>
						</li>
					</ul>
				</div>
				<div>
					<div v-if="!isLoading && schedule.status != 'approved'" class="m-btn-group  btn-group" role="group" aria-label="First group">
						<button
							:disabled="inputDisable"
							v-if="showBtnActions('close-schedule')"
							type="button"
							class="m-btn btn btn-success btn-schedule-actions"
							data-toggle="modal" 
							data-target="#close-schedule"
						>
							<i class="la la-lock"></i>
							Fechar cronograma
						</button>
						<button
							:disabled="inputDisable"
							v-if="showBtnActions('approve-schedule')"
							type="button"
							class="m-btn btn btn-success btn-schedule-actions" 
							data-toggle="modal" 
							data-target="#approve-schedule"
              v-b-tooltip.hover="'Aprovar cronograma'"
						>
              <i class="la la-thumbs-up"></i>
						</button>
						<button
							:disabled="inputDisable"
							v-if="showBtnActions('repprove-schedule')"
							type="button"
							class="m-btn btn btn-danger btn-schedule-actions" 
							data-toggle="modal" 
							data-target="#reprove-schedule"
              v-b-tooltip.hover="'Reprovar cronograma'"
						>
              <i class="la la-thumbs-down"></i>
						</button>
						<button
							:disabled="inputDisable"
							v-if="showBtnActions('save-schedule')"
							type="button" 
							class="m-btn btn btn-info btn-schedule-actions"
							data-toggle="modal" 
							data-target="#store-folder"
							@click="save"
						>
							<i class="la la-save"></i>
							Salvar
						</button>
        	</div>
				</div>
			</div>
		</div>
		<u-portlet title="Informações Básicas">
			<div class="form-group row">
				<div class="col-lg-6">
					<label class="form-control-label">Tipo da Campanha</label>
					<div class="m-radio-inline mt-3">
						<label class="m-radio m-radio--success">
							<input :disabled="inputDisable" type="radio" name="type" v-model="schedule.type"  value="monetized"> Paga
							<span></span>
						</label>
						<label class="m-radio m-radio--focus">
							<input :disabled="inputDisable" type="radio" name="type" v-model="schedule.type" value="subsidized"> Bonificada
							<span></span>
						</label>
						<label class="m-radio m-radio--accent">
							<input :disabled="inputDisable" type="radio" name="type" v-model="schedule.type" value="subsidized-bemobi"> Bonificada Bemobi
							<span></span>
						</label>
					</div>
				</div>
				<div class="col-lg-6">
					<label class="form-control-label">Período</label>
					<div class="input-daterange input-group">
						<date-picker :disabled="inputDisable" placeholder="Início" v-model="schedule.date_start" :config="datePickerOptions"></date-picker>
						<div class="input-group-append">
							<span class="input-group-text">até</span>
						</div>
						<date-picker :disabled="inputDisable" placeholder="Término" v-model="schedule.date_finish" :config="datePickerOptions"></date-picker>
					</div>
				</div>
			</div>
		</u-portlet>
		<u-portlet title="Campanhas">
			<div slot="tools">
				<button :disabled="inputDisable" v-if="schedule.status == 'setup' || schedule.status == 'under-review'" type="button" data-toggle="modal" data-target="#new-campaign" class="btn btn-sm btn-outline-success m-btn m-btn--icon">
					<span>
						<i class="la la-plus"></i>
						<span>Campanha</span>
					</span>
				</button>
			</div>
			<table class="table table-hover p-0 m-0 m-table m-table--head-separator-primary">
				<thead>
				<tr>
					<th></th>
					<th>Campanha</th>
					<th>Serviço</th>
					<th>Provedor de Conteúdo</th>
					<th>Provedor de Mídia</th>
					<th>Canal</th>
					<th></th>
				</tr>
				</thead>
				<tbody v-if="isLoading">
					<tr>
						<td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
							<div class="m-loader m-loader--danger" style="width: 30px; display: inline-block;"></div>
						</td>
					</tr>
				</tbody>
				<tbody v-if="!campaigns.length && !isLoading">
					<tr>
						<td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
							Nenhuma campanha disponível
						</td>
					</tr>
				</tbody>	
				<tbody v-else v-for="(campaign, index) in campaigns" :key="index">
					<tr class="campaign">
						<td class="pt-4 pb-4 text-center show-more-info" @click="openMoreInfo(index)" style="cursor: pointer">
							<span class="text-muted">
								<i class="la la-angle-down"></i>
							</span>
						</td>
						<td class="pt-4 pb-4">
							{{ campaign.campaignName }}
						</td>
						<td class="pt-4 pb-4">
							{{ campaign.serviceName }}
						</td>
						<td class="pt-4 pb-4">
							{{ campaign.companyContent }}
						</td>
						<td class="pt-4 pb-4">
							{{ campaign.mediaProvider }}
						</td>
						<td class="pt-4 pb-4">
							{{ campaign.channel }}
						</td>
						<td class="pt-4 pb-4 text-center" @click="removeCampaign(campaign.id, index)" style="cursor: pointer">
							<a class="text-muted">
								<i class="la la-remove"></i>
							</a>
						</td>
					</tr>
					<tr class="more-info" style="display: none">
						<td colspan="7" class="pt-4">
							<u-portlet :header="false" style="display: none" class="more-info-portlet mb-4">
								<div class="m-widget29 p-0">
									<div class="m-widget_content p-0">
										<div class="m-widget_content-items">
											<div class="m-widget_content-item">
												<span>Offer Key</span>
												<span class="m--font-dark">
													{{ campaign.offerKey }}
												</span>
											</div>
											<div class="m-widget_content-item">
												<span>Tarifa</span>
												<span class="m--font-dark">R$ {{ campaign.tariff.toString().replace('.', ',') }}</span>
											</div>
											<div class="m-widget_content-item">
												<span>Periodicidade de Renovação</span>
												<span class="m--font-dark" v-html="`${campaign.renewable} dia${campaign.renewable > 1 ? 's' : ''}`"></span>
											</div>
											<div class="m-widget_content-item">
												<span>Gratuidade</span>
												<span class="m--font-dark" v-html="` ${campaign.trialDays > 1 ? campaign.trialDays + ' dias' : '-'}`"></span>
											</div>
										</div>
									</div>
									<div class="m-widget_content p-0 mt-4">
										<div class="m-widget_content-items">
											<div class="m-widget_content-item">
												<span>ID Campanha</span>
												<span class="m--font-dark">
													{{ campaign.hashCampaignId }}
												</span>
											</div>
											<div class="m-widget_content-item">
												<span>ID MUV</span>
													<span class="m--font-dark">{{ campaign.idMuv }}</span>
												</div>
											<div class="m-widget_content-item">
												<span>Keyword</span>
												<span class="m--font-dark">{{ campaign.keywords }}</span>
											</div>
											<div class="m-widget_content-item">
												<span>Quantidade de Disparos</span>
												<span class="m--font-dark">{{ campaign.quantityShots }}</span>
											</div>
										</div>
									</div>
									<div class="mt-4"></div>
									<hr/>
										<div class="m-widget_content p-0 mt-4">
										<div class="m-widget_content-items text-center">
                      <router-link :to="{name: 'campaign.info', params: {id: campaign.campaignId}}" target="_blank" class="btn  btn-sm btn-outline-info m-btn m-btn--icon">
                        <span>
													<i class="la la-plus"></i>
													<span> mais informações</span>
												</span>
                      </router-link>
										</div>
								</div>
								</div>
							</u-portlet>
						</td>
					</tr>
				</tbody>
			</table>
		</u-portlet>
		<u-modal title="Adicionar Campanha" id="new-campaign" size="lg">
			<div slot="body">
				<div class="form-group row">
					<div class="col-lg-6">
						<label class="form-control-label">
							Provedor de Conteúdo
						</label>
						<select class="form-control" @change="getServicesByCompany" v-model="campaign.companyContentId">
							<option 
								v-if="companiesContent.length"
								v-for="(company, companyIndex) in companiesContent"
								:key="companyIndex"
								:value="company.id"
							>
								{{ company.name }}
							</option>
						</select>
					</div>
					<div class="col-lg-6">
						<label class="form-control-label">
							Serviço
						</label>
						<select class="form-control" v-model="campaign.serviceId">
							<option 
								v-if="services.length"
								v-for="(service, serviceIndex) in services"
								:key="serviceIndex"
								:value="service.id"
							>
								{{ service.name }}
							</option>
						</select>
					</div>
				</div>
				<div class="form-group row">
					<div class="col-lg-6">
						<label class="form-control-label">Canal</label>
						<select class="form-control" @change="getCampaignsAndProvidersByMediaChannel" v-model="campaign.mediaChannelId">
							<option 
								v-if="mediaChannels.length"
								v-for="(channel, channelIndex) in mediaChannels"
								:key="channelIndex"
								:value="channel.id"
							>
								{{ channel.name }}
							</option>
						</select>
					</div>
					<div class="col-lg-6">
						<label class="form-control-label">Provedor de Mídia</label>
						<select class="form-control" v-model="campaign.mediaProviderId">
							<option 
								v-if="mediaProviders.length"
								v-for="(mediaProvider, mediaProviderIndex) in mediaProviders"
								:key="mediaProviderIndex"
								:value="mediaProvider.id"
							>
								{{ mediaProvider.name }}
							</option>
						</select>
					</div>
				</div>
				<div class="form-group row">
					<div class="col-lg-6">
						<label class="form-control-label">Campanha</label>
						<select class="form-control" v-model="campaign.campaignId">
							<option 
								v-if="scheduleCampaigns.length"
								v-for="(scheduleCampaign, scheduleCampaignIndex) in scheduleCampaigns"
								:key="scheduleCampaignIndex"
								:value="scheduleCampaign.id"
							>
								{{ scheduleCampaign.name }}
							</option>
						</select>
					</div>
					<div class="col-lg-6">
						<label class="form-control-label">Quantidade de disparos</label>
						<input type="number" min="1" v-mask="'##########################'" class="form-control" v-model="campaign.quantityShots" />
					</div>

				</div>
				<div class="form-group row" style="display: none">
					<div class="col-lg-3">
						<label class="form-control-label">ID Campanha</label>
					</div>
					<div class="col-lg-3">
						<label class="form-control-label">Keyword</label>
					</div>
				</div>
				<div class="form-group row">
					<div class="col-lg-6">
						<label class="form-control-label">ID MUV</label>
						<input class="form-control" v-model="campaign.idMuv"/>
					</div>
				</div>
			</div>
			<div slot="footer">
				<button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
				<button type="button" class="btn btn-primary" data-dismiss="modal" @click="storeCampaign()">Salvar</button>
			</div>
		</u-modal>

		<!-- MODAL CLOSE SCHEDULE -->
		<u-modal id="close-schedule" title="Fechar Cronograma">
			<div slot="body">
				<p>Deseja realmente fechar este cronograma?</p>
			</div>
			<div slot="footer">
				<button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
				<button type="button" class="btn btn-primary" data-dismiss="modal" @click="closeSchedule">Sim</button>
			</div>
		</u-modal>

		<!-- MODAL APPROVE SCHEDULE -->
		<u-modal id="approve-schedule" title="Aprovar Cronograma">
			<div slot="body">
				<p>Deseja realmente aprovar este cronograma?</p>
			</div>
			<div slot="footer">
				<button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
				<button type="button" class="btn btn-primary" data-dismiss="modal" @click="approveSchedule">Sim</button>
			</div>
		</u-modal>

		<!-- MODAL REPROVE SCHEDULE -->
		<u-modal id="reprove-schedule" title="Reprovar Cronograma">
			<div slot="body">
				<p>Deseja realmente reprovar este cronograma?</p>
			</div>
			<div slot="footer">
				<button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
				<button type="button" class="btn btn-primary" data-dismiss="modal" @click="disapproveSchedule">Sim</button>
			</div>
		</u-modal>
	</div>
</template>

<script>
import DatePicker from 'vue-bootstrap-datetimepicker';
import UPortlet from '@/components/UPortlet';
import UModal from '@/components/UModal';
import UTable from '@/components/UTable';
import Service from '../services/ScheduleService';

export default {
  name: 'schedule-info',
  components: {
    UTable,
    UPortlet,
    DatePicker,
    UModal
  },
  data() {
    return {
			service: new Service(),
			services: {},
			companiesContent: {},
			mediaChannels: {},
			scheduleCampaigns: {},
			mediaProviders: {},
			inputDisable: !this.$authorization.can('save-campaign'),
			campaign: {
				campaignScheduleId: '',
				campaignId: '',
				companyContentId: '',
				mediaChannelId: '',
				mediaProviderId: '',
				quantityShots: '',
				serviceId: '',
				idMuv: '',
				quantityShots: '',
			},
      campaigns: [],
      schedule: {
        id: null,
        type: null,
        date_start: null,
        date_finish: null,
      },
      datePickerOptions: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
			},
			isLoading: true,
			qtdButtons: 0,
    }
  },
  created() {
		this.loadSchedule();
	},
  methods: {
    loadSchedule() {
      const scheduleID = this.$route.params.id;
      this.service.getSchedule(scheduleID)
        .then((response) => {
        	const schedule = response.data.schedule;
					this.schedule = {
						id: schedule.id,
						type: schedule.type,
						date_start: this.$helper.convertDatePtBr(schedule.date_start, false),
						date_finish: this.$helper.convertDatePtBr(schedule.date_finish, false),
						status: schedule.status,
					};
				this.companiesContent = response.data.companiesContent;
				this.mediaChannels = response.data.mediaChannels;

				this.campaigns = response.data.scheduleCampaigns;
				this.campaign.campaignScheduleId = this.schedule.id;
				this.isLoading = false;
      }).catch((error) => {
        console.log(error);
      });
		},
		save() {
			const schedule = {
						id: this.schedule.id,
						type: this.schedule.type,
						date_start: this.$helper.convertDateUS(this.schedule.date_start),
						date_finish: this.$helper.convertDateUS(this.schedule.date_finish),
					};
			this.service.save(schedule)
				.then((response) => {
					swal({
						title: 'Sucesso!',
						text: "Cronograma atualizado com sucesso.",
						type: 'success',
					});
				})
				.catch((error) => {
					swal({
						title: 'Ops!',
						text: "Ocorreu um erro ao atualizar o cronograma.",
						type: 'error',
					});
					swal('Ops!', this.$helper.formRequestErrorsMessage(error, "Ocorreu um erro ao atualizar o cronograma."), 'error');
					console.log(error.response);
				})
		},
		storeCampaign() {
			const campaign = {
				campaignScheduleId: this.campaign.campaignScheduleId,
				campaignId: this.campaign.campaignId,
				companyContentId: this.campaign.companyContentId,
				mediaChannelId: this.campaign.mediaChannelId,
				mediaProviderId: this.campaign.mediaProviderId,
				quantityShots: this.campaign.quantityShots,
				serviceId: this.campaign.serviceId,
				idMuv: this.campaign.idMuv,
				quantityShots: this.campaign.quantityShots,
			}

			this.service.storeCampaign(campaign)
			.then((response) => {
				this.clearCampaign();
				this.loadSchedule();
			})
			.catch((error) => {
				swal('Ops!', this.$helper.formRequestErrorsMessage(error), 'error');
				console.log(error.response);
			})
		},
		removeCampaign(id, index) {
      swal({
        title: 'Certeza ?',
        text: "Você deseja relamente excluir esta campanha?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d7321e',
        cancelButtonColor: '#ebedf2',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
					this.campaigns.splice(index, 1);
					this.service.removeCampaign(id)
						.catch((error) => {
							console.log(error.response);
						});
        }
      });
		},
		validateCampaign() {
			if (!this.campaign.campaignId || !this.campaign.companyContentId || !this.campaign.mediaChannelId ||
					!this.campaign.mediaProviderId || !this.campaign.quantityShots ||
					!this.campaign.serviceId || !this.campaign.idMuv || !this.campaign.quantityShots) {
						swal({
							title: 'Ops!',
							text: "Preencha todos os campos corretamente.",
							type: 'error',
						});
						return false;
					}

			return true;
		},
		clearCampaign() {
			this.campaign = {
				campaignId: '',
				companyContentId: '',
				mediaChannelId: '',
				mediaProviderId: '',
				quantityShots: '',
				serviceId: '',
				idMuv: '',
				quantityShots: '',
			}
		},
    openMoreInfo(index) {
        const elCampaign = $('.campaign').eq(index);
        const elIcon = $('.show-more-info i').eq(index);
        const elCampaignInfo = $('.more-info').eq(index);
        const elCampaignPortlet = elCampaignInfo.find('.more-info-portlet');
        const isActive = elCampaign.hasClass('more-info-active');

        if (!isActive) {
          elIcon.removeClass('la la-angle-down');
          elIcon.addClass('la la-angle-up');
          elCampaign.addClass('more-info-active');
          elCampaignInfo.slideDown('slow');
          elCampaignPortlet.slideDown(200);
        } else {
            elIcon.removeClass('la la-angle-up');
            elIcon.addClass('la la-angle-down');
            elCampaignPortlet.slideUp(160, function () {
              elCampaign.removeClass('more-info-active');
              elCampaignInfo.hide();
            });
        }
    },
		getTariffValue(tariff) {
			if (tariff.length) {
				return tariff[0].price.toFixed(2).replace('.', ',');
			}

			return '-';
		},
		getServicesByCompany() {
			this.service.getServicesByCompany(this.campaign.companyContentId)
				.then((response) => {
					this.services = response.data;
				})
				.catch((error) => {
					console.log(error.response);
				})
		},
		getCampaignsAndProvidersByMediaChannel() {
			this.service.getMediaProvidersByChannel(this.campaign.mediaChannelId)
				.then((response) => {
					this.mediaProviders = response.data;
				})
				.catch((error) => {
					console.log(error.response);
				})

				this.service.getCampaignsByMediaChannel(this.campaign.mediaChannelId)
				.then((response) => {
					this.scheduleCampaigns = response.data;
				})
				.catch((error) => {
					console.log(error.response);
				})
		},
		closeSchedule() {
			this.service.closeSchedule(this.schedule.id)
				.then((response) => {
					this.schedule.status = 'approval-pending';
					swal({
						title: 'Sucesso!',
						text: "Cronograma fechado com sucesso.",
						type: 'success',
					});
				})
				.catch((error) => {
					swal({
							title: 'Ops!',
							text: "Ocorreu um erro ao fechar o cronograma.",
							type: 'error',
						});
					console.log(error.response);
				})
		},
		approveSchedule() {
			this.service.approveSchedule(this.schedule.id)
				.then((response) => {
					this.schedule.status = 'approved';
					swal({
						title: 'Sucesso!',
						text: "Cronograma aprovado com sucesso.",
						type: 'success',
					});
				})
				.catch((error) => {
					swal({
							title: 'Ops!',
							text: "Ocorreu um erro ao aprovar o cronograma.",
							type: 'error',
						});
					console.log(error.response);
				})
		},
		disapproveSchedule() {
			this.service.disapproveSchedule(this.schedule.id)
				.then((response) => {
					this.schedule.status = 'under-review';
					swal({
						title: 'Sucesso!',
						text: "Cronograma reprovado com sucesso.",
						type: 'success',
					});
				})
				.catch((error) => {
					swal({
							title: 'Ops!',
							text: "Ocorreu um erro ao reprovar o cronograma.",
							type: 'error',
						});
					console.log(error.response);
				})
		},
		incrementButtonActions() {
			this.qtdButtons++
		},
		showBtnActions(type) {
			let status = this.schedule.status;

			if (!this.$authorization.can(type)) {
				return false;
			}

			switch (type) {
				case 'approve-schedule':
				case 'repprove-schedule':
					return status == 'approval-pending';
				case 'save-schedule':
				case 'close-schedule':
					return status == 'setup' || status == 'under-review';
			}
		},
  },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        if(!vm.$authorization.can('campaign-schedules')) {
          return next('/');
        }
      })
  },
}
</script>

<style>
  .m--font-dark {
    color: #575962 !important;
    font-size: 1.1rem !important;
  }
  .more-info-active {
    td {
        background: #f8f9fa;
        border-bottom: 0 !important;
    }
  }
  .more-info {
    td {
        background: #f8f9fa;
        border-top: 0 !important;
        padding-right: 1.7rem;
        padding-left: 1.7rem;
    }

    .m-portlet {
        margin-bottom: 2rem;
    }
  }
</style>

