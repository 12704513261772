export default class ScheduleServices {
  constructor() {
    this.axios = axios;
  }

  getSchedules() {
    return this.axios.get('campaign/schedules');
  }

  store(data) {
    return this.axios.post('campaign/schedule', data);
  }

  destroy(id) {
    return this.axios.post(`campaign/schedule/${id}/destroy`);
  }

  save(schedule) {
    return this.axios.post(`campaign/schedule/${schedule.id}`, schedule);
  }

  getSchedulesByStatus(status) {
    return this.axios.get(`campaign/schedules/by/status/${status}`);
  }

  getSchedule(id) {
    return this.axios.get(`campaign/schedules/${id}`);
  }

  getServicesByCompany(id) {
    return this.axios.get(`campaign/schedule/company/${id}/get-services`);
  }

  getCampaignsByMediaChannel(id) {
    return this.axios.get(`campaign/schedule/channel/${id}/get-campaigns`);
  }

  getMediaProvidersByChannel(id) {
    return this.axios.get(`campaign/schedule/channel/${id}/get-media-providers`);
  }

  storeCampaign(campaign) {
    return this.axios.post(`campaign/schedule/new/schedule-campaign`, campaign);
  }

  removeCampaign(id) {
    return this.axios.post(`campaign/schedule/remove-campaign/${id}`);
  }

  closeSchedule(id) {
    return this.axios.post(`campaign/schedule/${id}/close`);
  }

  approveSchedule(id) {
    return this.axios.post(`campaign/schedule/${id}/approve`);
  }

  disapproveSchedule(id) {
    return this.axios.post(`campaign/schedule/${id}/disapprove`);
  }
}
