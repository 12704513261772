<template>
  <div class="row">
    <div class="col-12">
      <u-portlet :title="channel.name">
        <div class="for-group row">
          <div class="col-lg-6">
            <label for="" class="form-control-label">
              Keywords
            </label>
            <v-select :disabled="inputDisable" v-model="media.keywords"
            :options="keywords"></v-select>
          </div>
        </div>
        <div class="form-group m-form__group row">
          <div class="col-lg-12">
            <label>Mensagem</label>
            <textarea :disabled="inputDisable" :maxlength="(160 - quality.length)"
            class="form-control m-input" v-model="media.message"
              rows="3"></textarea>
            <span class="float-right text-muted">
                {{ (media.message) ? media.message.length : 0 }}/ {{ 160 - quality.length }}
            </span>
          </div>
        </div>
        <div class="form-group m-form__group row">
          <div class="col-lg-12">
            <label>Fraseologia de Qualidade</label>
            <textarea :disabled="inputDisable" maxlength="160" disabled
            class="form-control m-input" v-model="quality"
              rows="3"></textarea>
            <span class="float-right text-muted">
                <!-- {{ (quality) ? quality.length : 0 }}/160 -->
            </span>
          </div>
        </div>
        <div class="form-group m-form__group row">
          <div class="col-lg-12">
            <label>Fraseologia Final</label>
            <textarea disabled
            v-model="quality"
            class="form-control m-input"
              rows="3"></textarea>
            <span class="float-right text-muted">
                <!-- {{ (quality) ? quality.length : 0 }}/160 -->
            </span>
          </div>
        </div>
      </u-portlet>
    </div>
  </div>
</template>

<script>
import Switches from 'vue-switches';
import vSelect from 'vue-select';
import UPortlet from '@/components/UPortlet';
import Support from '../../support';

export default {
  name: 'SMS',
  components: {
    Switches,
    UPortlet,
    vSelect
  },
  created() {
    this.getKeywords();
    let offer = this.offer;
    let tariff = offer.tariff[0];
    let periodicity = tariff.periodRenew + 'dia(s)';
    if (tariff.periodRenew == 7) periodicity = 'semana';
    if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'mes';

    this.changePhraseologyOfferKnowMore();
    if (this.$store.state.campaign.editing.campaign.channel.technology == this.$store.state.campaign.editing.channelForm
    && this.$store.state.campaign.media) {
      let media = Object.assign({}, this.$store.state.campaign.media);
      this.media = media;
      this.media.quality = this.quality;
    } else {
      this.media = {};
      this.media.quality = this.quality;
    }
  },
  watch: {
    '$store.state.campaign.editing.offer': function (newValue) {
      this.offer = newValue;
    },
    'offer': {
      handler: function (newValue, oldValue) {
        let offer = this.offer;
        let tariff = offer.tariff[0];
        let periodicity = tariff.periodRenew + 'dia(s)';
        if (tariff.periodRenew == 7) periodicity = 'semana';
        if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'mes';

        this.changePhraseologyOfferKnowMore();
        this.media.quality = this.quality;
      }
    },
    'media': {
      handler: function(newValue, oldValue) {
        this.$store.commit('setEditingMedia', newValue);
        this.changePhraseologyOfferKnowMore();
      },
      deep: true
    },
    'channel': function (newValue) {
      if (this.$store.state.campaign.media && newValue.id != this.$store.state.campaign.media.channel_id) {
        this.media = {};
        this.media.quality = this.quality;
        this.getKeywords();
        this.media.keywords = [];
      } else {
        this.getKeywords();
        let media = Object.assign({}, this.$store.state.campaign.media);
        this.media = media;
        this.media.quality = this.quality;
      }
    }
  },
  computed: {
    channel() {
      return this.$store.state.campaign.editing.channel;
    },
  },
  data() {
    return {
        media: {},
        offer: this.$store.state.campaign.editing.offer,
        quality: '',
        keywords: [],
        inputDisable: !this.$authorization.can('save-campaign'),
        serviceName: this.$store.state.campaign.editing.campaign.service.name,
    }
  },
  methods: {
    getKeywords() {
      let campaign = this.$store.state.campaign.editing.campaign;
      campaign.technology = this.$store.state.campaign.editing.channelForm;
      axios.post('/campaign/keywords-by-media-channel', campaign)
        .then(response => this.keywords = response.data)
        .catch(error => {
          this.keywords = [];
        });
    },
    changePhraseologyOfferKnowMore() {
      let offer = this.offer;
      let tariff = offer.tariff[0];
      let periodicity = tariff.periodRenew + 'dia(s)';
      if (tariff.periodRenew == 7) periodicity = 'semana';
      if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'mes';

      let message = this.media.message ? `${this.media.message + '. '}` : ''
      this.quality = `${this.serviceName}: ${message}`;
      if(offer.trialDays > 0) {
        this.quality += `Assine agora por RS${Support.decimalToFixed(tariff.price)}/${periodicity} e ganhe ${offer.trialDays} dias gratis enviando${this.media.keywords ? ` ${this.media.keywords}` : ''}.`;
      } else {
        this.quality += `Para assinar agora por RS${Support.decimalToFixed(tariff.price)}/${periodicity}, envie ${this.media.keywords || ''}.`;
      }
    }
  }
}
</script>