<template>
    <span :class="`m-badge m-badge--${color} ${shape}`">{{ name }}</span>
</template>
<script>
export default {
  name: 'u-badge',
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'metal'
    },
    shape: {
      type: String,
      default: 'm-badge--wide'
    }
  }
}
</script>