<template>
  <div class="row">
    <div class="col-12">
      <u-portlet :title="channel.name">
        <div class="for-group row mb-4">
          <div class="col-lg-6">
            <label for="" class="form-control-label">
              Keywords
            </label>
            <v-select :disabled="inputDisable" v-model="media.keywords" multiple
            :options="keywords"></v-select>
          </div>
        </div>
        <div class="form-group m-form__group row">
          <div class="col-lg-12">
              <label>1º Card de Optin</label>
            <textarea :disabled="inputDisable" class="form-control m-input"
            :maxlength="(94 - quality.length)"
            v-model="media.first_card_optin"
            @keyup="changePhraseologyOfferKnowMore"
              rows="3"></textarea>
            <span class="float-right text-muted">
              {{ (media.first_card_optin) ? media.first_card_optin.length : 0 }}/ {{ 94 - quality.length }}
            </span>
          </div>
        </div>
        <div class="form-group m-form__group row">
          <div class="col-lg-12">
            <label>Fraseologia de Qualidade</label>
            <textarea :maxlength="(160 - quality.lenth)" disabled
            class="form-control m-input" v-model="quality"
              rows="3"></textarea>
            <span class="float-right text-muted">
                <!-- {{ (quality) ? quality.length : 0 }}/160 -->
            </span>
          </div>
        </div>
        <div class="form-group m-form__group row">
          <div class="col-lg-12">
            <label>Fraseologia Final</label>
            <textarea disabled
            v-model="quality"
            class="form-control m-input"
              rows="3"></textarea>
            <span class="float-right text-muted">
                <!-- {{ (quality) ? quality.length : 0 }}/160 -->
            </span>
          </div>
        </div>
        <div class="form-group m-form__group row">
          <div class="col-lg-12">
            <label>2º Card de Optin</label>
            <textarea :disabled="inputDisable" class="form-control m-input"
            maxlength="94"
            v-model="media.second_card_optin"
            @keyup="changePhraseologyOfferKnowMore"
              rows="3"></textarea>
            <span class="float-right text-muted">
              {{ (media.second_card_optin) ? media.second_card_optin.length : 0 }}/ {{ 94 - quality2.length }}
            </span>
          </div>
        </div>
        <div class="form-group m-form__group row">
          <div class="col-lg-12">
            <label>Fraseologia de Qualidade</label>
            <textarea :maxlength="(160 - quality2.lenth)" disabled
            class="form-control m-input" v-model="quality2"
              rows="3"></textarea>
            <span class="float-right text-muted">
                <!-- {{ (quality) ? quality.length : 0 }}/160 -->
            </span>
          </div>
        </div>
        <div class="form-group m-form__group row">
          <div class="col-lg-12">
            <label>Fraseologia Final</label>
            <textarea disabled
            v-model="quality2"
            class="form-control m-input"
              rows="3"></textarea>
            <span class="float-right text-muted">
                <!-- {{ (quality) ? quality.length : 0 }}/160 -->
            </span>
          </div>
        </div>
      </u-portlet>
    </div>
  </div>
</template>

<script>
import Switches from 'vue-switches';
import UPortlet from '@/components/UPortlet';
import InputTag from 'vue-input-tag'
import Vue from 'vue'
import vSelect from 'vue-select';
import Support from '../../support';

Vue.component('input-tag', InputTag);

export default {
  name: 'SIMCARD',
  components: {
    Switches,
    UPortlet,
    vSelect,
  },
  computed: {
    channel() {
      return this.$store.state.campaign.editing.channel;
    }
  },
  created()  {
    this.getKeywords();
    let offer = this.offer;
    let tariff = offer.tariff[0];
    let periodicity = tariff.periodRenew + 'dia(s)';
    if (tariff.periodRenew == 7) periodicity = 'semana';
    if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'mes';
    let textPeriodicity = tariff.periodRenew + ' dia(s)';
    if (tariff.periodRenew == 7) textPeriodicity = 'semana';
    if (tariff.periodRenew == 31 || tariff.periodRenew == 30) textPeriodicity = 'mes';
    this.changePhraseologyOfferKnowMore();

    if (this.$store.state.campaign.editing.campaign.channel.technology == this.$store.state.campaign.editing.channelForm
    && this.$store.state.campaign.media) {
      let media = Object.assign({}, this.$store.state.campaign.media);
      this.media = media;
      this.media.quality = this.quality;
    } else {
      this.media = {};
      this.media.quality = this.quality;
    }
  },
  watch: {
    'media': {
      handler: function(newValue, oldValue) {
        this.$store.commit('setEditingMedia', newValue);
      },
      deep: true
    },
    'channel': function (newValue) {
      if (newValue.id != this.$store.state.campaign.media.channel_id) {
        this.media = {};
        this.media.quality = this.quality;
        this.getKeywords();
        this.media.keywords = [];
      } else {
        this.getKeywords();
        let media = Object.assign({}, this.$store.state.campaign.media);
        this.media = media;
        this.media.quality = this.quality;
      }
    },
    '$store.state.campaign.editing.offer': function (newValue) {
      this.offer = newValue;
    },
    'offer': {
      handler: function (newValue, oldValue) {
        let offer = this.offer;
        let tariff = offer.tariff[0];
        let periodicity = tariff.periodRenew + 'dia(s)';
        if (tariff.periodRenew == 7) periodicity = 'semana';
        if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'mes';
        let textPeriodicity = tariff.periodRenew + ' dia(s)';
        if (tariff.periodRenew == 7) textPeriodicity = 'semana';
        if (tariff.periodRenew == 31 || tariff.periodRenew == 30) textPeriodicity = 'mes';
        this.changePhraseologyOfferKnowMore();
      }
    }
  },
  data() {
    return {
        media: {},
        quality: '',
        quality2: '',
        offer: this.$store.state.campaign.editing.offer,
        keywords: [],
        inputDisable: !this.$authorization.can('save-campaign'),
        serviceName: this.$store.state.campaign.editing.campaign.service.name,
    }
  },
  methods: {
    getKeywords() {
      let campaign = this.$store.state.campaign.editing.campaign;
      axios.post('/campaign/keywords-by-media-channel', campaign)
        .then(response => this.keywords = response.data)
        .catch(error => {
          this.keywords = [];
        });
    },
    changePhraseologyOfferKnowMore() {
      let offer = this.offer;
      let tariff = offer.tariff[0];
      let periodicity = tariff.periodRenew + 'dia(s)';
      if (tariff.periodRenew == 7) periodicity = 'semana';
      if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'mes';
      let textPeriodicity = tariff.periodRenew + ' dia(s)';
      if (tariff.periodRenew == 7) textPeriodicity = 'semana';
      if (tariff.periodRenew == 31 || tariff.periodRenew == 30) textPeriodicity = 'mes';

      let first_card_optin = this.media.first_card_optin ? this.media.first_card_optin + '. ' : '';
      let second_card_optin = this.media.second_card_optin ? this.media.second_card_optin + '. ' : '';
      
      if(offer.trialDays > 0) {
        this.quality = `${this.serviceName}: ${first_card_optin}gratis por ${tariff.periodRenew} dias. Apos, RS${Support.decimalToFixed(tariff.price)}/${textPeriodicity}. Para assinar, clique OK.`;
        this.quality2 = `${this.serviceName}: ${second_card_optin}Para confirmar a assinatura do servico ${this.serviceName} por ${tariff.periodRenew} dias gratis e depois RS${Support.decimalToFixed(tariff.price)}/${textPeriodicity}, clique OK.`;
      } else {
        this.quality = `${this.serviceName}: ${first_card_optin}Apenas RS${Support.decimalToFixed(tariff.price)}/${textPeriodicity}. Para assinar, clique OK.`;
        this.quality2 = `${this.serviceName}: ${second_card_optin}Para confirmar a assinatura do serviço ${this.serviceName} por RS${Support.decimalToFixed(tariff.price)}/${textPeriodicity}, clique OK.`;
      }
      }
  }
}
</script>