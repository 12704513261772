<template>
    <div class="row">
        <div class="col-12">
            <u-portlet :title="channel.name">
              <div class="form-group m-form__group row">
                <div class="col-lg-12">
                  <label>Descrição</label>
                  <textarea :disabled="inputDisable" class="form-control m-input" v-model="media.description"
                    rows="3"></textarea>
                </div>
              </div>
              <div class="form-group">
                  <label class="form-control-label">
                    Banner
                  </label>
                  <input :disabled="inputDisable" @change="uploadFile"
                  type="file" ref="banner" class="form-control"/>
              </div>
              <div v-if="media && media.banner_file">
                <img :src="$store.state.campaign.s3Path + media.banner_file" alt="">
              </div>
            </u-portlet>
        </div>
    </div>
</template>

<script>
import Switches from 'vue-switches';
import UPortlet from '@/components/UPortlet';

export default {
  name: 'WAP',
  components: {
    Switches,
    UPortlet
  },
  created() {
    if (this.$store.state.campaign.editing.campaign && this.$store.state.campaign.editing.campaign.channel.technology == this.$store.state.campaign.editing.channelForm
    && this.$store.state.campaign.media) {
      let media = Object.assign({}, this.$store.state.campaign.media);
      this.media = media;
    } else {
      this.media = {};
    }
  },
  watch: {
    'media': {
      handler: function(newValue, oldValue) {
        this.$store.commit('setEditingMedia', newValue);
      },
      deep: true
    },
    'channel': function (newValue) {
      if (newValue.id != this.$store.state.campaign.media.channel_id) {
        this.media = {};
      } else {
        let media = Object.assign({}, this.$store.state.campaign.media);
        this.media = media;
      }
    }
  },
  computed: {
    channel() {
      return this.$store.state.campaign.editing.channel;
    }
  },
  data() {
    return {
      media: {},
      inputDisable: !this.$authorization.can('save-campaign'),
    }
  },
  methods: {
    uploadFile() {
      let banner_file = this.$refs.banner.files[0];
      let wapFile = {
        banner_file
      }
      this.$store.commit('appendFiles', wapFile);
    }
  }
}
</script>