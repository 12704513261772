export default {
  scheduleStatusLabel(status) {
    const result = {
      color: '',
      name: ''
    };

    switch (status) {
      case 'setup':
        result.name = 'setup';
        result.color = 'info';
        break;
      case 'in-progress':
        result.name = 'em andamento';
        result.color = 'accent';
        break;
      case 'approved':
        result.name = 'aprovado';
        result.color = 'success';
        break;
      case 'reproved':
        result.name = 'reprovado';
        result.color = 'danger';
        break;
      case 'approval-pending':
        result.name = 'aguardando aprovação';
        result.color = 'warning';
        break;
      case 'finished':
        result.name = 'encerrada';
        result.color = 'metal';
        return 'encerrada';
      case 'under-review':
        result.name = 'revisão';
        result.color = 'danger';
        break;
      default:
        result.name = 'desconhecido';
        result.color = 'metal';
    }

    return result;
  },

  scheduleTypeLabel(type) {
    switch (type) {
      case 'monetized':
        return 'Paga';
      case 'subsidized':
        return 'Bonificada';
      case 'subsidized-bemobi':
        return 'Bonificada Bemobi';
    }
  },
  decimalToFixed(value, quantity = 2) {
    if (value) {
      return parseFloat(value).toFixed(quantity);
    }

    return '';
  }
};
