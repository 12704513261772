<template>
    <div>
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">Cronogramas</h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                      <li class="m-nav__item m-nav__item--home">
                          <router-link :to="{name: 'home'}" class="m-nav__link m-nav__link--icon">
                              <i class="m-nav__link-icon la la-home"></i>
                          </router-link>
                      </li>
                      <li class="m-nav__separator">-</li>
                      <li class="m-nav__item">
                          <router-link :to="{name: 'campaign'}" class="m-nav__link">
                              <span class="m-nav__link-text">Campanhas</span>
                          </router-link>
                      </li>
                      <li class="m-nav__separator">-</li>
                      <li class="m-nav__item">
                        <span class="m-nav__link-text">Gestão de Cronogramas</span>
                      </li>
                    </ul>
                </div>
                <div>
                    <button v-if="$authorization.can('create-campaign-schedule')" data-toggle="modal" data-target="#new-schedule" class="btn btn-outline-danger m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill" type="button">
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="m-content">
            <div class="row">
                <div class="col-12">
                    <div class="m-portlet m-portlet--full-height">
                        <div class="m-portlet__body pt-5">
                            <u-table :columns="['ID', 'Início', 'Término',  'Campanha', 'Status', 'Servicos', 'Anunciantes', 'Vazão', '']">
                                <tr v-if="loading">
                                    <td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
                                        <div class="m-loader m-loader--danger" style="width: 30px; display: inline-block;"></div>
                                    </td>
                                </tr>
                                <tr v-if="!loading && schedules.length === 0">
                                    <td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
                                        Nenhum cronograma disponível
                                    </td>
                                </tr>
                                <tr v-for="(schedule, scheduleIndex) in schedules" :key="scheduleIndex">
                                    <td class="pt-4 pb-4">
                                        {{ schedule.id }}
                                    </td>
                                    <td class="pt-4 pb-4">
                                        {{ $helper.convertDatePtBr(schedule.date_start, false) }}
                                    </td>
                                    <td class="pt-4 pb-4">
                                        {{ $helper.convertDatePtBr(schedule.date_finish, false) }}
                                    </td>
                                    <td class="pt-4 pb-4">
                                        {{ support.scheduleTypeLabel(schedule.type) }}
                                    </td>
                                    <td class="pt-4 pb-4">
                                        <span :class="`m-badge m-badge--${support.scheduleStatusLabel(schedule.status).color} m-badge--wide`">
                                            {{ support.scheduleStatusLabel(schedule.status).name }}
                                        </span>
                                    </td>
                                    <td class="pt-4 pb-4">
                                        {{ schedule.services}}
                                    </td>
                                    <td class="pt-4 pb-4">
                                        {{ schedule.advertisers }}
                                    </td>
                                    <td class="pt-4 pb-4">
                                        {{ schedule.flow_rate }}
                                    </td>
                                    <td class="pt-4 pb-4">
                                        <router-link :to="{name: 'campaign.schedule.info', params: { id: schedule.id }}" style="margin-right: 10px">
                                            <i class="fa flaticon-cogwheel-2"></i>
                                        </router-link>

																				<a href="#destroy-schedule" data-toggle="modal" @click="() => {
																					scheduleId = schedule.id;
																					indexSchedule = scheduleIndex
																				}">
                                            <i class="fa flaticon-delete"></i>
                                        </a>
                                    </td>
                                </tr>
                            </u-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <u-modal id="new-schedule" title="Novo Cronograma">
            <div slot="body">
                <div ref="form">
                    <div class="form-group">
                        <label class="form-control-label">Tipo da Campanha</label>
                        <div class="m-radio-inline mt-3">
                            <label class="m-radio m-radio--success">
                                <input class="form-control" v-model="schedule.type" v-validate="'required'" type="radio" name="type"  value="monetized"> Paga
                                <span></span>
                            </label>
                            <label class="m-radio m-radio--focus">
                                <input v-model="schedule.type" v-validate="'required'" type="radio" name="type" value="subsidized"> Bonificada
                                <span></span>
                            </label>
                            <label class="m-radio m-radio--accent">
                                <input v-model="schedule.type" v-validate="'required'" type="radio" name="type" value="subsidized-bemobi"> Bonificada Bemobi
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-6">
                        <label class="form-control-label">Início</label>
                        <date-picker v-model="schedule.date_start" :config="datePickerOptions" placeholder="__/__/____"></date-picker>
                    </div>
                    <div class="col-lg-6">
                        <label class="form-control-label">Término</label>
                        <date-picker v-model="schedule.date_finish" :config="datePickerOptions" placeholder="__/__/____"></date-picker>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
                <button type="button" ref="btnStore" class="btn m-btn btn-primary" @click="store">Continuar</button>
            </div>
        </u-modal>

				<u-modal id="destroy-schedule" title="Excluir Cronograma">
            <div slot="body">
                <p>Deseja realmente excluir este cronograma?</p>
            </div>
            <div slot="footer">
                <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
                <button type="button" ref="btnStore" class="btn m-btn btn-primary" data-dismiss="modal" @click="destroy">Excluir</button>
            </div>
        </u-modal>
    </div>
</template>

<script>
import DatePicker from 'vue-bootstrap-datetimepicker';
import UTable from '@/components/UTable';
import UModal from '@/components/UModal';
import ScheduleService from '../services/ScheduleService';
import Support from '../support';

export default {
  name: 'campaigns',
  components: {
    DatePicker,
    UModal,
    UTable
  },
  data() {
    return {
      loading: true,
      support: Support,
      service: new ScheduleService(),
			schedules: [],
			scheduleId: '',
			indexSchedule: '',
      schedule: {
        type: null,
        date_start: null,
        date_finish: null
      },
      datePickerOptions: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
      },
    }
  },
  created() {
    this.loadSchedules();
  },
  methods: {
    loadSchedules() {
      this
        .service
        .getSchedules()
        .then((response) => {
            this.schedules = response.data;
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            console.log(error);
        });
    },
    /**
     * Send schedule to in-progress
     */
    store() {
      this.animateButton(true, 'Processando');

      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.animateButton(false, 'Continuar');
          swal('Ops!', 'Preencha os campos corretamente.');
          return;
        }

        //Format data
        const request = Object.assign({}, this.schedule);
        request.date_start = this.$helper.convertDateUS(this.schedule.date_start);
        request.date_finish = this.$helper.convertDateUS(this.schedule.date_finish);

        this
          .service
          .store(request)
          .then((response) => {
            const scheduleCreated = response.data;
            this.animateButton(false, 'Continuar');
            this.$router.push({name: 'campaign.schedule.info', params: {id: scheduleCreated.id}});
            $('#new-schedule').modal('hide');
          })
          .catch((error) => {
            this.animateButton(false, 'Continuar');
            swal('Ops!', this.$helper.formRequestErrorsMessage(error), 'error');
          });
      });
		},
		destroy() {
			this.service
			.destroy(this.scheduleId)
			.then(() => {
				this.schedules.splice(this.indexSchedule, 1);
			})
			.catch((error) => {
				swal('Ops!', this.$helper.formRequestErrorsMessage(error), 'error');
			});
		},

    /**
     * Animate modal button
     * @param loader
     * @param text
     */
    animateButton(loader, text) {
      const el = $(this.$refs.btnStore);
      const form = $('input, select');
      if (loader) {
        form.prop('disabled', true);
        el.addClass('m-loader m-loader--light m-loader--right');
      } else {
        form.prop('disabled', false);
        el.removeClass('m-loader m-loader--light m-loader--right');
      }
      el.html(text);
    }
  },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        if(!vm.$authorization.can('campaign-schedules')) {
          return next('/');
        }
      })
  },
}
</script>
