<template>
  <div id="auth-login">
    <div class="m-login__signin">
      <div class="m-login__head">
        <h3 class="text-center">Bem vindo ao SGVAS</h3>
        <h5 class="text-center mt-4">Acesse sua conta</h5>
      </div>
      <form class="m-login__form m-form" @submit.prevent="login">
        <div v-show="hasError" class="m-alert m-alert--outline m-alert--outline-2x alert alert-primary alert-dismissible fade show" role="alert">
          <button type="button" class="close" data-dismiss="alert" aria-label="Close"></button>
          <strong>Ops!</strong> E-mail ou senha inválidos.
        </div>
        <div v-if="sendResetPassword" class="m-alert m-alert--outline m-alert--outline-2x alert alert-success alert-dismissible fade show" role="alert">
          <button type="button" class="close" @click="sendResetPassword = false" data-dismiss="alert" aria-label="Close"></button>
          <strong>Sucesso!</strong> Acesse seu email para redefinir a senha.
        </div>
        <div class="form-group m-form__group">
          <input v-model="credentials.email" class="form-control m-input"  type="email" placeholder="E-mail">
        </div>
        <div class="form-group m-form__group">
          <input v-model="credentials.password" type="password"  class="form-control m-input m-login__form-input--last" placeholder="Senha">
        </div>
        <div class="col m--align-right pr-0 mt-3">
          <a href="#forgot-password" data-toggle="modal" class="m-link">Esqueci minha senha</a>
        </div>
        <div class="m-login__form-action">
          <button class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Entrar</button>
        </div>
      </form>
    </div>

    <u-modal id="forgot-password" title="Reenviar Senha">
			<div slot="body">
        <label for="">Email</label>
				<div class="form-group m-form__group">
          <input v-model="credentials.email" class="form-control m-input"  type="email" placeholder="E-mail">
        </div>
			</div>
			<div slot="footer">
					<button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Fechar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="resetPassword" >Enviar</button>
			</div>
		</u-modal>
  </div>
</template>

<script>
import UModal from '@/components/UModal';

export default {
  name: 'auth-login',
  components: {
			UModal,
  },
  data() {
    return {
      user: {},
      hasError: false,
      credentials: {
        email: '',
        password: '',
      },
      sendResetPassword: false,
    }
  },
  created() {
    if (this.$store.getters.authenticated) {
      this.user = this.$store.state.auth.user;
      //this.redirectToHome();
    }
  },
  methods: {
    login() {
      this.$store.dispatch('login', this.credentials)
        .then((response) => {
          this.user = response.data.user;
          this.redirectToHome();
        })
        .catch((error) => {
          this.hasError = true;
        })
    },
    redirectToHome() {
      if (this.user) {
        switch (this.user.company_profile_id) {
          case 3:
          case 4:
          case 5:
            this.$router.push({name: 'home.partner'});
            break;
          default:
            this.$router.push({name: 'home'});
            break;
        }
      }
    },
    resetPassword() {
      this.sendResetPassword = true;
      this.$store.dispatch('resetPassword', this.credentials.email);
    },
  }
}
</script>