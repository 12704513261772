<template>
    <div id="campaign">
      <div class="m-subheader ">
        <div class="d-flex align-items-center">
          <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">Campanhas</h3>
            <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
              <li class="m-nav__item m-nav__item--home">
                <router-link :to="{name: 'home'}" class="m-nav__link m-nav__link--icon">
                  <i class="m-nav__link-icon la la-home"></i>
                </router-link>
              </li>
              <li class="m-nav__separator">-</li>
              <li class="m-nav__item">
                <router-link :to="{name: 'campaign'}" class="m-nav__link">
                  <span class="m-nav__link-text">Gestão de Campanhas</span>
                </router-link>
              </li>
              <li class="m-nav__separator">-</li>
              <li class="m-nav__item">
                <span class="m-nav__link-text">Campanha</span>
              </li>
            </ul>
          </div>
          <div v-show="campaign && campaign.status" class="m-btn-group btn-group" role="group" aria-label="Button group with nested dropdown">
            <button
              :disabled="inputDisable"
              v-if="$authorization.can('save-campaign')"
              @click="updateCampaign"
              type="button"
              class="m-btn btn btn-info"
              v-b-tooltip.hover="'Salvar alterações'"
            >
              <i class="la la-save"></i>Salvar
            </button>
            <button
              :disabled="inputDisable"
              v-if="$authorization.can('campaign-approve-uotz')
              && campaign.status == 'pending-approval-uotz'"
              data-target="#approve"
              data-toggle="modal"
              type="button"
              class="m-btn btn btn-success"
              v-b-tooltip.hover="'Aprovar campanha'"
            >
              <i class="la la-thumbs-up"></i>
            </button>
            <button
              :disabled="inputDisable"
              v-if="$authorization.can('campaign-approve-claro')
              && campaign.status == 'pending-approval-claro'"
              data-target="#approve"
              data-toggle="modal"
              type="button"
              class="m-btn btn btn-success"
              v-b-tooltip.hover="'Aprovar campanha'"
            >
              <i class="la la-thumbs-up"></i>
            </button>
            <button
              :disabled="inputDisable"
              v-if="$authorization.can('campaign-reprove-uotz')
              && campaign.status == 'pending-approval-uotz'"
              data-target="#repprove"
              data-toggle="modal"
              type="button"
              class="m-btn btn btn-primary"
              v-b-tooltip.hover="'Reprovar campanha'"
            >
              <i class="la la-thumbs-down"></i>
            </button>
            <button
              :disabled="inputDisable"
              v-if="$authorization.can('campaign-reprove-claro')
              && campaign.status == 'pending-approval-claro'
              || (campaign.status == 'elegible'
              && $authorization.can('campaign-reprove-claro')
              )"
              data-target="#repprove"
              data-toggle="modal"
              type="button"
              class="m-btn btn btn-primary"
              v-b-tooltip.hover="'Reprovar campanha'"
            >
              <i class="la la-thumbs-down"></i>
            </button>
            <button
              :disabled="inputDisable"
              v-if="$authorization.can('campaign-send-approval')
              && campaign.status == 'setup'
              || campaign.status == 'disapproved-uotz'
              || campaign.status == 'disapproved-claro'"
              @click="sendToApprovation"
              type="button"
              class="m-btn btn btn-default"
              v-b-tooltip.hover="'Enviar para aprovação'"
            >
              <i class="fa fa-paper-plane"></i>
              Enviar para aprovação
            </button>
          </div>
        </div>
      </div>
      <div class="m-content">
        <div class="row">
          <!-- div informação campanha -->
          <div class="col-lg-7">
            <u-portlet title="Informações da Campanha">
              <div class="form-group">
                <label class="form-control-label">Nome da Campanha</label>
                <input :disabled="inputDisable" v-model="campaign.name" type="text" class="form-control">
              </div>
              <div class="form-group row">
                <div class="col-lg-6">
                  <label class="form-control-label">Provedor de Conteúdo</label>
                  <select
                    name="type"
                    class="form-control"
                    v-model="campaign.provider_content_id"
                    @change="getServicesByProvidersContent"
                  >
                    <option
                      v-if="providersContent.length"
                      v-for="providerContent in providersContent"
                      :key="providerContent.id"
                      :value="providerContent.id"
                    >
                      {{ providerContent.name }}
                    </option>
                  </select>
                </div>

                <div class="col-lg-6">
                  <label class="form-control-label">Serviço</label>
                  <select :disabled="inputDisable" class="form-control"
                    @change="setOffers"
                    v-model="campaign.service_id">
                    <option v-for="service in services" :key="service.id"
                    :value="service.id">
                      {{ service.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-6">
                  <label class="form-control-label">Oferta</label>
                  <select :disabled="inputDisable" v-model="campaign.service_offer_id"
                    class="form-control">
                    <option v-for="offer in offers" :key="offer.id"
                      :value="offer.id">
                      {{ offer.name }}
                    </option>
                  </select>
                </div>

                <div class="col-lg-6">
                  <label class="form-control-label">Tipo da Campanha</label>
                <select :disabled="inputDisable" name="type" id="type" v-model="campaign.type" class="form-control">
                  <option value="paga">Paga</option>
                  <option value="bonificada">Bonificada</option>
                </select>
                </div>
              </div>
              <div class="form-group">
                <u-table :columns="['Tarifa', 'Periodicidade', 'Gratuidade']">
                  <tr>
                    <td>
                      <span v-if="offer && offer.tariff && offer.tariff[0]">
                        R$ {{ offer.tariff[0].price }}
                      </span>
                      <span v-else>
                        --
                      </span>
                    </td>
                    <td>
                      <span v-if="offer && offer.tariff
                        && offer.tariff[0].periodRenew > 0">
                        <span v-if="offer.tariff[0].periodRenew == 7">
                          Semanal
                        </span>
                        <span v-if="offer && offer.tariff && offer.tariff[0].periodRenew == 31
                        || offer.tariff[0].periodRenew == 30">
                        Mensal
                        </span>
                        <span v-else>
                          {{ offer.tariff[0].periodRenew }} dia(s)
                        </span>
                      </span>
                      <span v-else>
                        --
                      </span>
                    </td>
                    <td>
                      <span v-if="offer && offer.trialDays
                        && offer.trialDays > 0">
                        {{ offer.trialDays }} dia(s)
                      </span>
                      <span v-else>
                        0
                      </span>
                    </td>
                  </tr>
                </u-table>
              </div>

              <div class="form-group row">
                  <div class="col-lg-6">
                    <label class="form-control-label">Canal</label>
                    <select :disabled="inputDisable" @change="setCompanyAndProvider" v-model="campaign.channel_id" class="form-control">
                      <option v-for="(channel, index) in channels" :key="index + channel.name + channel.id" :value="channel.id">
                        {{ channel.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">
                      Provedor de Mídia
                    </label>
                    <select :disabled="inputDisable" v-model="campaign.company_id" class="form-control">
                      <option v-for="company in companies" :key="company.id" :value="company.id">
                        {{ company.name }}
                      </option>
                    </select>
                  </div>
              </div>
            </u-portlet>
          </div>
          <!-- fim div informação campanha -->

          <!-- div cronogramas -->
          <div class="col-lg-5">
            <u-portlet title="Cronogramas">
              <div slot="tools">
                <!-- <button class="btn btn-success m-btn btn-sm m-btnicon m-btnicon-only m-btncustom m-btnpill">
                    <i class="la la-plus"></i>
                </button> -->
              </div>
              <div style="height: 338px; max-height: 338px; overflow: auto">
                <u-table :columns="['Período', 'Status', '']">
                  <tr v-if="campaign.schedules && campaign.schedules.length > 0"
                    v-for="schedule in campaign.schedules" :key="schedule.id">
                    <td>
                      {{ $helper.convertDatePtBr(schedule.campaign_schedule.date_start, false) }} à {{ $helper.convertDatePtBr(schedule.campaign_schedule.date_finish, false) }}
                    </td>
                    <td>
                      {{ support.scheduleStatusLabel(schedule.campaign_schedule.status).name  }}
                    </td>
                    <td></td>
                  </tr>
                  <tr v-else>
                    <td colspan="3" class="text-center">
                      Nenhum cronograma disponível
                    </td>
                  </tr>
                </u-table>
              </div>
            </u-portlet>
          </div>
          <!-- fim div cronogramas -->
        </div>
        <div class="row">
          <div class="col-12">
            <component :is="form"></component>
          </div>
        </div>
      </div>

      <!-- modal aprovação -->
      <u-modal title="Aprovar Campanha" id="approve" size="lg">
        <div slot="body">
          <div class="form-group">
            <label>
              Deseja realmente aprovar a campanha <b>{{ campaign.name }}</b>?
            </label>
          </div>
        </div>
        <div slot="footer">
          <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">
            Cancelar
          </button>
          <button data-dismiss="modal"
           @click="approve" type="button" class="btn btn-success">
            Aprovar
          </button>
        </div>
      </u-modal>
      <!-- fim modal aprovação -->

      <!-- modal reprovação -->
      <u-modal title="Reprovar Campanha" id="repprove" size="lg">
        <div slot="body">
          <div class="form-group">
            <label>Motivo da reprovação da campanha</label>
            <textarea v-model="campaign.description" class="form-control" rows="5"></textarea>
          </div>
        </div>
        <div slot="footer">
          <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">
            Cancelar
          </button>
          <button data-dismiss="modal"
           @click="repprove" type="button" class="btn btn-primary">
            Reprovar
          </button>
        </div>
      </u-modal>
      <!-- fim modal reprovação -->
    </div>
</template>

<script>
import UPortlet from '@/components/UPortlet';
import UTable from '@/components/UTable';
import UBadge from '@/components/UBadge';
import SATPUSH from './form-channels/SATPUSH.vue';
import USSD from './form-channels/USSD.vue';
import IVR from './form-channels/IVR.vue';
import WAP from './form-channels/WAP.vue';
import NODATA from './form-channels/NODATA.vue';
import SMS from './form-channels/SMS.vue';
import SIMCARD from './form-channels/SIMCARD.vue';
import UModal from '@/components/UModal';
import toastr from 'toastr';
import Support from '../support';

export default {
  name: 'campaign',
  components: {
    UModal,
    SATPUSH,
    USSD,
    IVR,
    WAP,
    NODATA,
    SMS,
    SIMCARD,
    UBadge,
    UPortlet,
    UTable,
  },
  created() {
    this.getCampaign();
  },
  data() {
    return {
      campaign: {},
      services: [],
      offers: [],
      channels: [],
      companies: [],
      providersContent: [],
      channel: {},
      support: Support,
      inputDisable: !this.$authorization.can('save-campaign'),
      loading: true,
    }
  },
  computed: {
    form() {
      return this.$store.state.campaign.editing.channelForm;
    },
    offer: {
      get: function () {
        let offer = this.offers.find(offer => offer.id == this.campaign.service_offer_id);
        this.$store.commit('setOffer', offer);

        return this.campaign.offer = offer;
      },
      set: function (newValue) {
        let offer = this.offers.find(offer => offer.id == newValue);
        this.$store.commit('setOffer', offer);

        return offer;
      }
    }
  },
  watch: {
    'campaign': {
      handler:  function (newValue, oldValue) {
        this.$store.commit('setCampaign', newValue);
      },
      deep: true
    },
    'channels': function(newValue, oldValue) {
      if (this.campaign && this.campaign.channel_id) {
        this.companies = this.channels
        .find(channel => channel.id == this.campaign.channel_id)
        .company_media_channels;
        this.channel = this.channels.find(channel => channel.id == this.campaign.channel_id);
        this.$store.commit('setChannelForm', this.channel.technology);
        this.$store.commit('setChannel', this.channel);
      }
    },
    'campaign.channel_id': function(newValue, oldValue) {
      if (oldValue != null && newValue != oldValue) {
        this.campaign.company_id = null;
        this.channel = this.channels.find(channel => channel.id == newValue);
        this.$store.commit('setChannelForm', this.channel.technology);
        this.$store.commit('setChannel', this.channel);
        if (this.channel.id == this.campaign.channel_id) {
          this.$store.commit('setMedia', this.campaign.media);
        }
        this.$store.commit('clearFiles');
      }
    },
    'campaign.service_offer_id': function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.offer = newValue;
      }
    }
  },
  methods: {
    getServices() {
      axios.get('/campaign/get-creation-data')
        .then(response => {
          // this.services = response.data.services;
          this.channels = response.data.channels;
        })
        .catch(error => console.error(error.response))
    },
    getCampaign() {
      let campaign_id = this.$route.params.id;
      axios.get(`/campaign/${campaign_id}`)
        .then(response => {
          this.campaign = response.data.campaign;
          this.providersContent = response.data.providersContent;
          if (response.data.campaign.media) {
            this.$store.commit('setMedia', response.data.campaign.media)
          }

          if (!response.data.campaign.media) {
            this.$store.commit('setMedia', {})
          }
          this.setOffers();
          this.getServices();
          this.getServicesByProvidersContent();
        })
        .catch((error) => {
          console.error(error)
          this.loading = false;
        });
    },
    getServicesByProvidersContent() {
      if (this.campaign && this.campaign.provider_content_id) {
        axios.get(`/campaign/get-services-provider-content/${this.campaign.provider_content_id}`)
        .then(response => {
          this.services = response.data;
        })
        .catch(error => console.error(error))
      }
    },
    setOffers() {
      let serviceId = this.campaign.service_id;
      axios.get(`/service/offers/${serviceId}`)
        .then(response => this.offers = response.data
            .filter(offer => offer.campaign_id == null))
        .catch(error => console.error(error.response));
    },
    setCompanyAndProvider() {
      this.companies = this.channels
        .find(channel => channel.id == this.campaign.channel_id)
        .company_media_channels;
    },
    updateCampaign() {
      this.loading = true;
      let formData = this.mountFormData();
      if (this.campaign.status != 'setup') {
        swal({
          title: 'Atenção',
          text: 'Ao atualizar a campanha, a mesma voltará para o ciclo inicial de aprovação. Deseja realmente alterá-la?',
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Certo'
        })
          .then((result) => {
            if (result.value) {
              axios.post(`/campaign/${this.campaign.id}`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
                .then(response => {
                  this.getCampaign();
                  swal('Sucesso!', 'Campanha editada com sucesso!', 'success');
                })
                .catch(error => {
                  swal('Ops!', this.$helper.formRequestErrorsMessage(error), 'error');
                });
            }
            if(result.dismiss) {
              swal('Sucesso!', 'A campanha não será alterada', 'info')
            }
          })
      } else {
        axios.post(`/campaign/${this.campaign.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            this.getCampaign();
            swal('Sucesso!', 'Campanha editada com sucesso!', 'success');
          })
          .catch(error => {
            console.log(error.response.data)
            swal('Ops!', this.$helper.formRequestErrorsMessage(error), 'error');
          });
      }
    },
    mountFormData() {
      let media = this.$store.state.campaign.editing.media;
      this.campaign.media = media;
      this.campaign.technology = this.$store.state.campaign.editing.channelForm;
      let formData = new FormData;
      let files = this.$store.state.campaign.editing.files;

      if (Object.keys(files).length > 0) {
        for (var key in files) {
          formData.append(key, files[key]);
        }
      }
      for (var key in this.campaign) {
        if (key == 'media' || key == 'service'
          || key == 'offer' || key == 'channel') {
          formData.append(key, JSON.stringify(this.campaign[key]))
        } else {
          formData.append(key, this.campaign[key])
        }
      }

      return formData;
    },
    approve() {
      axios.post(`/campaign/${this.campaign.id}/approve`)
        .then(response => {
          this.getCampaign();
          swal('Sucesso!',  'A campanha foi aprovada com sucesso, em breve os responsáveis receberão um email informando o novo status.', 'success');
        })
        .catch(error => {
          swal('Ops!', 'Não foi possível aprovar a campanha, tente novamente mais tarde ou entre em contato com o suporte', 'error');
        })
    },
    repprove() {
      axios.post(`/campaign/${this.campaign.id}/repprove`, this.campaign)
        .then(response => {
          this.getCampaign();
          this.campaign.description = '';
          swal('Sucesso!',  'A campanha foi reprovada com sucesso, em breve os responsáveis receberão um email informando o novo status.', 'success');
        })
        .catch(error => {
          swal('Ops!', 'Não foi possível reprovar a campanha, tente novamente mais tarde ou entre em contato com o suporte', 'error');
        })
    },
    sendToApprovation() {
      axios.post(`/campaign/${this.campaign.id}/send-approvation`)
        .then(response => {
          this.getCampaign();
          swal('Sucesso!',  'A campanha foi enviada para aprovação com sucesso, em breve os responsáveis receberão um email informando o novo status.', 'success');
        })
        .catch(error => {
          swal('Ops!', 'Não foi possível enviar a campanha para aprovação, tente novamente mais tarde ou entre em contato com o suporte', 'error');
        })
    }
  }

}
</script>

