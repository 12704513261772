<template>
  <div class="row">
    <div class="col-12">
      <u-portlet :title="channel.name">
        <div class="form-group row">
          <div class="col-lg-6">
            <label class="form-control-label">Modelo</label>
            <select :disabled="inputDisable" class="form-control" v-model="media.model_id">
              <option v-if="editingChannel.models.length == 0"
                      value="Não cadastrado" selected disabled>
                Não cadastrado
              </option>
              <option v-else
                      :value="model.id"
                      v-for="model in editingChannel.models" :key="model.id">
                {{ model.model }}
              </option>
            </select>
          </div>
        </div>
        <div class="for-group row">
          <div class="col-lg-6">
            <label for="" class="form-control-label">
              Keywords
            </label>
            <v-select :disabled="inputDisable" v-model="media.keywords" multiple
                      :options="keywords"></v-select>
          </div>
        </div>

        <h5 class="mt-5">Fraseologia</h5>
        <div class="m-separator"></div>

        <div class="m-accordion m-accordion--default m-accordion--solid" id="channel-accordion" role="tablist">
          <!--begin::Item-->
          <div class="m-accordion__item">
            <div class="m-accordion__item-head" role="tab" id="channel-accordion_item_1_head" data-toggle="collapse" href="#channel-accordion_item_1_body" aria-expanded="true">
              <span class="m-accordion__item-title">Oferta</span>
              <span class="m-accordion__item-mode"></span>
            </div>
            <div class="m-accordion__item-body collapse" id="channel-accordion_item_1_body" role="tabpanel" aria-labelledby="channel-accordion_item_1_head" data-parent="#channel-accordion">

              <div class="m-accordion__item-content">
                <label for="">Mensagem</label>
                <div class="form-group">
                  <textarea class="form-control" rows="5"
                    :disabled="inputDisable"
                    :maxlength="(158 - quality.length)"
                    v-model="media.offer_message"
                    placeholder="Descrição da oferta..."
                    @keyup="changePhraseologyOfferKnowMore"
                  >
                  </textarea>
                  <span class="float-right text-muted">
                  {{ (media.offer_message) ? media.offer_message.length : 0 }}/{{ 158 - quality.length }}
                  </span>
                </div>
                <div class="form-group m-form__group">

                  <label>Fraseologia de Qualidade</label>
                  <textarea maxlength="160" disabled
                            class="form-control m-input" v-model="quality"
                            rows="3"></textarea>
                  <span class="float-right text-muted">
                      <!-- {{ (quality) ? quality.length : 0 }}/160 -->
                  </span>
                  <label>Fraseologia Final</label>
                  <textarea disabled
                    v-model="quality"
                            class="form-control m-input"
                            rows="3"></textarea>
                  <span class="float-right text-muted">
                      <!-- {{ (quality) ? quality.length : 0 }}/160 -->
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div v-show="showMenu" class="m-accordion__item">
            <div class="m-accordion__item-head" role="tab" id="channel-accordion_item_1_head" data-toggle="collapse" href="#channel-accordion_item_2_body" aria-expanded="true">
              <span class="m-accordion__item-title">Menu</span>
              <span class="m-accordion__item-mode"></span>
            </div>
            <div class="m-accordion__item-body collapse" id="channel-accordion_item_2_body" role="tabpanel" aria-labelledby="channel-accordion_item_1_head" data-parent="#channel-accordion">

              <div class="m-accordion__item-content">
                <u-table :columns="['Opção', 'Título', 'Caracteres']">
                  <tr v-for="(option, index) in media.menu_options" :key="index">
                    <td class="pt-4 pb-4">
                      Opção {{ option.option_number }}
                    </td>
                    <td class="pt-4 pb-4">
                      <div class="form-group">
                        <input disabled v-model="media.menu_options[index].title" @keyup="setMediaOptions(media.menu_options)"
                               type="text" maxlength="29" class="form-control">
                      </div>
                    </td>
                    <td class="pt-4 pb-4">
                      {{ media.menu_options[index].title.length }} / 29
                    </td>
                  </tr>
                </u-table>
              </div>
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="m-accordion__item">
            <div class="m-accordion__item-head" role="tab" id="channel-accordion_item_1_head" data-toggle="collapse" href="#channel-accordion_item_3_body" aria-expanded="true">
                <span class="m-accordion__item-title">
                  Saiba Mais
                </span>
              <span class="m-accordion__item-mode"></span>
            </div>
            <div class="m-accordion__item-body collapse" id="channel-accordion_item_3_body" role="tabpanel" aria-labelledby="channel-accordion_item_1_head" data-parent="#channel-accordion">

              <div class="m-accordion__item-content">
                <label for="">Mensagem</label>
                <div class="form-group">
                  <textarea
                    :disabled="inputDisable"
                    :maxlength="(158 - quality2.length)"
                    class="form-control" rows="5"
                    v-model="media.know_more_message"
                    placeholder="Descrição da mensagem do menu..."
                    @keyup="changePhraseologyOfferKnowMore"
                  >
                  </textarea>
                  <span class="float-right text-muted">
                  {{ (media.know_more_message) ? media.know_more_message.length : 0 }}/{{ 158 - quality2.length }}
                  </span>
                </div>
                <div class="form-group m-form__group">

                  <label>Fraseologia de Qualidade</label>
                  <textarea maxlength="160" disabled
                            class="form-control m-input" v-model="quality2"
                            rows="3"></textarea>
                  <span class="float-right text-muted">
                      <!-- {{ (quality) ? quality.length : 0 }}/160 -->
                  </span>

                </div>
                <label>Fraseologia Final</label>
                <textarea disabled
                  v-model="quality2"
                          class="form-control m-input"
                          rows="3"></textarea>
                <span class="float-right text-muted">
                    <!-- {{ (quality) ? quality.length : 0 }}/160 -->
                </span>
              </div>
            </div>
          </div>
          <!--end::Item-->

        </div>

      </u-portlet>
    </div>
  </div>
</template>

<script>
  import Switches from 'vue-switches';
  import UPortlet from '@/components/UPortlet';
  import UTable from "@/components/UTable.vue";
  import InputTag from 'vue-input-tag';
  import Vue from 'vue';
  import vSelect from 'vue-select';
  import Support from '../../support';
  Vue.component('input-tag', InputTag);

  export default {
    name: 'SATPUSH',
    components: {
      Switches,
      UPortlet,
      UTable,
      vSelect,
    },
    data() {
      return {
        keywords: [],
        campaign: this.$store.state.campaign.editing.campaign,
        serviceName: this.$store.state.campaign.editing.campaign.service.name,
        media: {
          menu_options: [],
        },
        showMenu: false,
        quality: '',
        quality2: '',
        offer: this.$store.state.campaign.editing.offer,
        inputDisable: !this.$authorization.can('save-campaign'),
      }
    },
    computed: {
      editingChannel() {
        return this.$store.state.campaign.editing.channel;
      },
      channel() {
        return this.$store.state.campaign.editing.channel;
      },
    },
    created() {
      this.getKeywords();
      if (this.$store.state.campaign.editing.campaign.channel.technology == this.$store.state.campaign.editing.channelForm
        && this.$store.state.campaign.media) {
          let media = Object.assign({}, this.$store.state.campaign.media);
      console.log(media)
        this.media = media;
        this.media.quality = '';

      } else {
//        let offer = this.$store.state.campaign.editing.offer;
//        let tariff = offer.tariff[0];
//        let periodicity = tariff.periodRenew + 'dia(s)';
//        if (tariff.periodRenew == 7) {
//          periodicity = 'sem';
//        }
//
//        if (tariff.periodRenew == 31 || tariff.periodRenew == 30) {
//          periodicity = 'mes';
//        }
//
//        let textPeriodicity = tariff.periodRenew + ' dia(s)';
//        if (tariff.periodRenew == 7) {
//          textPeriodicity = 'sem';
//        }
//        if (tariff.periodRenew == 31 || tariff.periodRenew == 30) {
//          textPeriodicity = 'mes';
//        }

        this.changePhraseologyOfferKnowMore();
        this.media = {
          quality : '',
          menu_options: [
            {
              title: `QUERO contratar(RS${Support.decimalToFixed(tariff.price)}/${periodicity.substring(0, 3).toLowerCase()})`,
              option_number: 1
            },
            {
              title: 'MAIS informacoes',
              option_number: 3
            },
            {
              title: 'NÃO desejo contratar',
              option_number: 3
            },
          ]
        };
      }

    },
    watch: {
      'media': {
        handler: function(newValue, oldValue) {
          this.$store.commit('setEditingMedia', newValue);
        },
        deep: true
      },
      'channel': function (newValue) {
        if (this.$store.state.campaign.media
          && newValue.id != this.$store.state.campaign.media.channel_id) {
          this.media = {};
          this.media.quality = '';
          this.media.menu_options = [];
          this.getKeywords();
          this.media.keywords = [];
        } else {
          this.getKeywords();
          let media = Object.assign({}, this.$store.state.campaign.media);
          this.media = media;
          this.media.quality = '';
        }
      },
      'media.model_id': function(newValue, oldValue) {
        let offer = this.$store.state.campaign.editing.offer;
        if (offer) {
          var tariff = offer.tariff[0];
          var periodicity = tariff.periodRenew + 'dia(s)';
          if (tariff.periodRenew == 7) periodicity = 'sem';
          if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'mes';
          var textPeriodicity = tariff.periodRenew + ' dia(s)';
          if (tariff.periodRenew == 7) textPeriodicity = 'semana';
          if (tariff.periodRenew == 31 || tariff.periodRenew == 30) textPeriodicity = 'mes';
          this.changePhraseologyOfferKnowMore();
        }

        this.showMenu = false;
        let models = this.editingChannel.models;
        let model = models.find(model => model.id == newValue);
        if (this.media && this.media.model_id) {
          model = models.find(model => model.id == this.media.model_id);
          if (model && model.model != 'Texto + Texto') this.showMenu = true;
        }
        if (model && model.model != 'Texto + Texto') {
          this.showMenu = true;
          if (offer && tariff && !this.media.menu_options || this.media.menu_options.length == 0) {
            this.media.menu_options = [
              {
                title: `QUERO contratar(RS${Support.decimalToFixed(tariff.price)}/${periodicity.substring(0, 3).toLowerCase()})`,
                option_number: 1
              },
              {
                title: 'MAIS informacoes',
                option_number: 3
              },
              {
                title: 'NÂO desejo contratar',
                option_number: 3
              },
            ]
          }
        }

        if (!this.showMenu) this.media.menu_options = [];
      },
      '$store.state.campaign.editing.offer': function (newValue) {
        this.offer = newValue;
      },
      'offer': {
        handler: function (newValue, oldValue) {
          this.getKeywords();
          // let offer = this.offer;
          // let tariff = offer.tariff[0];
          // let periodicity = tariff.periodRenew + 'dia(s)';
          // if (tariff.periodRenew == 7) periodicity = 'semana';
          // if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'mes';
          // let service = this.$store.state.campaign.editing.campaign.service.name;
          // let textPeriodicity = tariff.periodRenew + ' dia(s)';
          // if (tariff.periodRenew == 7) textPeriodicity = 'semana';
          // if (tariff.periodRenew == 31 || tariff.periodRenew == 30) textPeriodicity = 'mes';
          this.changePhraseologyOfferKnowMore()
        }
      },
    },
    methods: {
      getKeywords() {
        let campaign = this.$store.state.campaign.editing.campaign;
        axios.post('/campaign/keywords-by-media-channel', campaign)
          .then(response => this.keywords = response.data)
          .catch(error => {
            this.keywords = [];
          })
      },
      setMediaOptions(options) {
        this.media.menu_options = options;
        this.$store.commit('setMenuOptions', options);
        this.$forceUpdate();
      },
      changePhraseologyOfferKnowMore() {
        let offer = this.offer;
        let tariff = offer.tariff[0];
        let periodicity = tariff.periodRenew + 'dia(s)';
        if (tariff.periodRenew == 7) periodicity = 'semana';
        if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'mes';
        let service = this.$store.state.campaign.editing.campaign.service.name;
        let textPeriodicity = tariff.periodRenew + ' dia(s)';
        if (tariff.periodRenew == 7) textPeriodicity = 'semana';
        if (tariff.periodRenew == 31 || tariff.periodRenew == 30) textPeriodicity = 'mes';

        let offer_message = this.media.offer_message ? `${this.media.offer_message + '. '}` : ''
        let know_more_message = this.media.know_more_message ? `${this.media.know_more_message + '. '}` : ''
        this.quality = `${this.serviceName}: ${offer_message}`;
        this.quality2 = `${this.serviceName}: ${know_more_message}`;

        if(offer.trialDays > 0) {
          this.quality += `Para assinar ${this.serviceName} por RS${Support.decimalToFixed(tariff.price)}/${textPeriodicity} e ganhar ${tariff.periodRenew} dias gratis, clique OK.`;
          this.quality2 += `Sao ${tariff.periodRenew} dias gratis, depois RS${Support.decimalToFixed(tariff.price)}/${textPeriodicity}. Para assinar clique OK agora.`;
        } else {
          this.quality += `por RS${Support.decimalToFixed(tariff.price)}/${textPeriodicity}. Clique OK para confirmar a contratacao.`;
          this.quality2 += `por RS${Support.decimalToFixed(tariff.price)}/${textPeriodicity}. Clique OK para confirmar a contratacao.`;
        }

        this.media.quality = this.quality;
      }
    }
  }
</script>