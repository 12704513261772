<template>
    <div class="row">
        <div class="col-12">
            <u-portlet :title="channel.name">
              <div class="form-group m-form__group row">
                <div class="col-lg-12">
                  <label>Mensagem</label>
                  <textarea :disabled="inputDisable" @keyup="changePhraseologyOfferKnowMore" class="form-control m-input"
                  :maxlength="(94 - quality.length)"
                  v-model="media.message"
                    rows="3"></textarea>
                  <span class="float-right text-muted">
                    {{ (media.message) ? media.message.length : 0 }}/{{ 94 - quality.length}}
                  </span>
                </div>
              </div>
              <div class="form-group m-form__group row">
                <div class="col-lg-12 mt-10">
                  <label>Fraseologia de Qualidade</label>
                  <textarea maxlength="160" disabled
                  class="form-control m-input" v-model="media.quality"
                    rows="3"></textarea>
                  <span class="float-right text-muted">
                      <!-- {{ (media.quality) ? media.quality.length : 0 }}/160 -->
                  </span>
                </div>
              </div>

              <div class="form-group m-form__group row">
                <div class="col-lg-12">
                  <label>Fraseologia Final</label>
                  <textarea disabled
                  v-model="media.quality"
                  class="form-control m-input"
                    rows="3"></textarea>
                  <span class="float-right text-muted">
                      <!-- {{ (quality) ? quality.length : 0 }}/160 -->
                  </span>
                </div>
              </div>
            </u-portlet>
        </div>
    </div>
</template>

<script>
import Switches from 'vue-switches';
import UPortlet from '@/components/UPortlet';
import Support from '../../support';

export default {
  name: 'USSD',
  components: {
    Switches,
    UPortlet
  },created() {
    let offer = this.offer;
    let tariff = offer.tariff[0];
    let periodicity = tariff.periodRenew + 'dia(s)';
    if (tariff.periodRenew == 7) periodicity = 'Semanal';
    if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'mensal';
    let textPeriodicity = tariff.periodRenew + ' dia(s)';
    if (tariff.periodRenew == 7) textPeriodicity = ' semana';
    if (tariff.periodRenew == 31 || tariff.periodRenew == 30) textPeriodicity = ' mes';
    this.changePhraseologyOfferKnowMore();
    if (this.$store.state.campaign.editing.campaign.channel.technology == this.$store.state.campaign.editing.channelForm
      && this.$store.state.campaign.media) {
      let media = Object.assign({}, this.$store.state.campaign.media);
      this.media = media;
      this.media.quality = this.quality;
    } else {
      this.media = {
        quality: this.quality
      };
    }
  },
  computed: {
    channel() {
      return this.$store.state.campaign.editing.channel;
    }
  },
  watch: {
    '$store.state.campaign.editing.offer': function (newValue) {
      this.offer = newValue;
    },
    'offer': {
      handler: function (newValue, oldValue) {
        let offer = this.offer;
        let tariff = offer.tariff[0];
        let periodicity = tariff.periodRenew + 'dia(s)';
        if (tariff.periodRenew == 7) periodicity = 'Semanal';
        if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'mensal';
        let textPeriodicity = tariff.periodRenew + ' dia(s)';
        if (tariff.periodRenew == 7) textPeriodicity = ' semana';
        if (tariff.periodRenew == 31 || tariff.periodRenew == 30) textPeriodicity = ' mes';
        
        changePhraseologyOfferKnowMore();
      }
    },
    'media': {
      handler: function(newValue, oldValue) {
        this.$store.commit('setEditingMedia', newValue);
      },
      deep: true
    },
    'channel': function (newValue) {
      if (newValue.id != this.$store.state.campaign.media.channel_id) {
        this.media = {};
        this.media.quality = this.quality;
      } else {
        let media = Object.assign({}, this.$store.state.campaign.media);
        this.media = media;
        this.media.quality = this.quality;
      }
    }
  },
  methods: {
    changePhraseologyOfferKnowMore() {
      let offer = this.offer;
      let tariff = offer.tariff[0];
      let periodicity = tariff.periodRenew + 'dia(s)';
      if (tariff.periodRenew == 7) periodicity = 'Semanal';
      if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'mensal';
      let textPeriodicity = tariff.periodRenew + ' dia(s)';
      if (tariff.periodRenew == 7) textPeriodicity = ' semana';
      if (tariff.periodRenew == 31 || tariff.periodRenew == 30) textPeriodicity = ' mes';

      let message = this.media.message ? `${this.media.message + '. '}` : ''
      this.quality = `${this.serviceName}: ${message}`;

      if(offer.trialDays > 0) {
        this.quality += `Grátis por ${tariff.periodRenew} dias. Após: RS${Support.decimalToFixed(tariff.price)}/${periodicity}. Para assinar envie 0.`;
        this.media.quality = this.quality;
      } else {
        this.quality += `Apenas RS${Support.decimalToFixed(tariff.price)}/${periodicity}. Para assinar agora, envie 0.`;
        this.media.quality = this.quality;
      }
    }
  },
  data() {
    return {
      media: {},
      offer: this.$store.state.campaign.editing.offer,
      quality: '',
      inputDisable: !this.$authorization.can('save-campaign'),
      serviceName: this.$store.state.campaign.editing.campaign.service.name,
    }
  }
}
</script>