<template>
  <div id="auth-login">
    <div v-if="!linkExpired && !loading" class="m-login__signin">
      <div class="m-login__head">
        <h3 class="text-center">Bem vindo ao SGVAS</h3>
        <h5 class="text-center mt-4">Cadastrar nova senha</h5>
      </div>
      <form class="m-login__form m-form" @submit.prevent="resetPassword">
        <div v-if="false" class="m-alert m-alert--outline m-alert--outline-2x alert alert-primary alert-dismissible fade show" role="alert">
          <button type="button" class="close" data-dismiss="alert" aria-label="Close"></button>
          <strong>Ops!</strong> E-mail ou senha inválidos.
        </div>      
        <div class="form-group m-form__group">
          <input v-model="credentials.password" type="password"  class="form-control m-input m-login__form-input--last" placeholder="Senha">
        </div>
        <div class="form-group m-form__group">
          <input v-model="credentials.confirmPassword" type="password"  class="form-control m-input m-login__form-input--last" placeholder="Confirmar senha">
        </div>
        <div class="m-login__form-action">
          <button class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air">Redefinir</button>
        </div>
      </form>
    </div>

    <div v-if="linkExpired && !loading" class="m-login__signin">
      <div class="m-login__head text-center">
        <h3>Link Expirado</h3>
        <button type="button" class="btn btn-primary mt-4" @click="$router.push({name:'home'})">
          <i class="fa fa-home mr-2"></i>
          Página inicial
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'reset-password',
  data() {
    return {
      user: {},
      hasError: false,
      credentials: {
        password: '',
        confirmPassword: '',
      },
      linkExpired: false,
      loading: true,
    }
  },
  created() {
    this.verifyRememberToken();
  },
  methods: {
    resetPassword() {
      if (this.validatePassword()) {
        this.$store.dispatch('changePassword', {
          token: this.$route.params.rt,
          password: this.credentials.password
        })
        .then((response) => {
          if (response.credentials) {
            this.$store.dispatch('login', response.credentials)
              .then((response) => {
                this.$router.push({name: 'home'});
              })
              .catch((error) => {
                console.log(error);
                swal('Ops!', 'Ocorreu um erro ao alterar a senha', 'error');
              });
          } else {
            swal('Ops!', 'Ocorreu um erro ao alterar a senha', 'error');
          }          
        });
      }
    },
    validatePassword() {
      if (!this.credentials.password) {
        swal('Ops!', 'A senha é obrigatória', 'error');
        return false;
      }
      
      if (this.credentials.password !== this.credentials.confirmPassword) {
        swal('Ops!', 'Os campos senha e confirmar senha devem ser iguais', 'error');
        return false;
      }

      if (this.credentials.password.length < 6) {
        swal('Ops!', 'A senha deve conter 6 ou mais caracteres', 'error');
        return false;
      }

      return true;
    },
    verifyRememberToken() {
      const rememberToken = this.$route.params.rt;

      if (rememberToken) {
        this.$store.dispatch('verifyRememberToken', rememberToken)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.linkExpired = true;
            this.loading = false;
          })
      } else {
        this.linkExpired = true;
        this.loading = false;
      }
    }
  }
}
</script>