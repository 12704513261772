<template>
  <div class="row">
    <div class="col-12">
      <u-portlet :title="channel.name">
        <div class="form-group row">
          <div class="col-lg-6">
            <label class="form-control-label">Público Alvo</label>
            <select :disabled="inputDisable" v-model="media.model_id"
            class="form-control">
                <option v-if="editingChannel.models.length == 0"
                value="Não cadastrado" selected disabled>
                  Não cadastrado
                </option>
                <option v-else
                :value="model.id"
                 v-for="model in editingChannel.models" :key="model.id">
                  {{ model.model }}
                </option>
            </select>
          </div>
        </div>

        <h5 class="mt-5">Mídia</h5>
        <div class="m-separator"></div>

        <div class="m-accordion m-accordion--default m-accordion--solid" id="channel-accordion" role="tablist">
          <!--begin::Item-->
          <div class="m-accordion__item">
            <div class="m-accordion__item-head" role="tab" id="channel-accordion_item_1_head" data-toggle="collapse" href="#channel-accordion_item_1_body" aria-expanded="true">
                <span class="m-accordion__item-title">
                  Oferta
                </span>
                <span class="m-accordion__item-mode"></span>
            </div>
            <div class="m-accordion__item-body collapse" id="channel-accordion_item_1_body" role="tabpanel" aria-labelledby="channel-accordion_item_1_head" data-parent="#channel-accordion" style="">
              <div class="m-accordion__item-content">
                <div class="form-group">
                  <textarea :disabled="inputDisable" v-model="media.media_message"
                  @keyup="changePhraseologyOfferKnowMore"
                  :maxlength="(325 - quality.length)"
                  class="form-control" rows="5" placeholder="Digite a sua mensagem da campanha aqui..."></textarea>
                  <span class="float-right text-muted">
                    {{ (media.media_message) ? media.media_message.length : 0 }}/{{ 325 - quality.length}}
                  </span>
                </div>
                <div class="form-group">
                  <div class="switch">
                    <switches :disabled="inputDisable" v-model="mediaIsRecorded" color="green"></switches>
                    <div class="switch-label">
                      Solicitar gravação de audio
                    </div>
                  </div>
                </div>
                <div class="form-group" v-show="!mediaIsRecorded">
                    <label class="form-control-label">Selecione um arquivo de audio</label>
                    <input :disabled="inputDisable" @change="setIvrFiles"
                    type="file" ref="media_message_file" id="media_message_file" class="form-control"/>
                </div>
                <div v-if="media && media.media_file">
                    <audio controls style="width: 100%">
                        <source :src="$store.state.campaign.s3Path + media.media_file">
                    </audio>
                </div>
                <div class="form-group m-form__group row">
                  <div class="col-lg-12 mt-10">
                    <label>Fraseologia de Qualidade</label>
                    <textarea maxlength="160" disabled
                    class="form-control m-input" v-model="media.quality"
                      rows="3"></textarea>
                    <span class="float-right text-muted">
                        <!-- {{ (media.quality) ? media.quality.length : 0 }}/160 -->
                    </span>
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <div class="col-lg-12">
                    <label>Fraseologia Final</label>
                    <textarea disabled
                    v-model="quality"
                    class="form-control m-input"
                      rows="3"></textarea>
                    <span class="float-right text-muted">
                        <!-- {{ (quality) ? quality.length : 0 }}/160 -->
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="m-accordion__item">
            <div class="m-accordion__item-head" role="tab" id="channel-accordion_item_2_head" data-toggle="collapse" href="#channel-accordion_item_2_body" aria-expanded="false">
                <span class="m-accordion__item-title">
                  Saiba Mais
                </span>
                <span class="m-accordion__item-mode"></span>
            </div>
            <div class="m-accordion__item-body collapse hide" id="channel-accordion_item_2_body" role="tabpanel" aria-labelledby="channel-accordion_item_2_head" data-parent="#channel-accordion" style="">
              <div class="m-accordion__item-content">
                <div class="form-group">
                  <textarea :disabled="inputDisable" v-model="media.know_more_message"
                  @keyup="changePhraseologyOfferKnowMore"
                  :maxlength="(325 - quality2.length)"
                  class="form-control" rows="5" placeholder="Digite a sua mensagem da campanha aqui...">
                  </textarea>
                  <span class="float-right text-muted">
                    {{ (media.know_more_message) ? media.know_more_message.length : 0 }}/{{ 325 - quality2.length}}
                  </span>
                </div>
                <div class="form-group">
                  <div class="switch">
                    <switches :disabled="inputDisable" v-model="knowMoreIsRecorded" color="green"></switches>
                    <div class="switch-label">
                        Solicitar gravação de audio
                    </div>
                  </div>
                </div>
                <div class="form-group" v-show="!knowMoreIsRecorded">
                  <label class="form-control-label">Selecione um arquivo de audio</label>
                  <input :disabled="inputDisable" @change="setIvrFiles"
                  type="file" id="know_more_file" ref="know_more_file" class="form-control"/>
                </div>
                <div v-if="media && media.know_more_file">
                    <audio controls style="width: 100%">
                        <source :src="$store.state.campaign.s3Path + media.know_more_file">
                    </audio>
                </div>
                <div class="form-group m-form__group row">
                  <div class="col-lg-12 mt-10">
                    <label>Fraseologia de Qualidade</label>
                    <textarea maxlength="160" disabled
                    class="form-control m-input" v-model="quality2"
                      rows="3"></textarea>
                    <span class="float-right text-muted">
                        <!-- {{ (media.quality) ? media.quality.length : 0 }}/160 -->
                    </span>
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <div class="col-lg-12">
                    <label>Fraseologia Final</label>
                    <textarea disabled
                    v-model="quality2"
                    class="form-control m-input"
                      rows="3"></textarea>
                    <span class="float-right text-muted">
                        <!-- {{ (quality) ? quality.length : 0 }}/160 -->
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Item-->
        </div>

      </u-portlet>
    </div>
  </div>
</template>

<script>
import Switches from 'vue-switches';
import UPortlet from '@/components/UPortlet';
import Support from '../../support';

export default {
  name: 'IVR',
  components: {
    Switches,
    UPortlet
  },
  computed: {
    editingChannel() {
      return this.$store.state.campaign.editing.channel;
    },
    channel() {
      return this.$store.state.campaign.editing.channel;
    }
  },
  created() {
    let offer = this.offer;
    let tariff = offer.tariff[0];
    let periodicity = tariff.periodRenew + 'dia(s)';
    if (tariff.periodRenew == 7) periodicity = 'Semanal';
    if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'Mensal';
    let service = this.$store.state.campaign.editing.campaign.service.name;
    let textPeriodicity = tariff.periodRenew + 'dia(s)';
    if (tariff.periodRenew == 7) textPeriodicity = 'semana';
    if (tariff.periodRenew == 31 || tariff.periodRenew == 30) textPeriodicity = 'mes';
    this.changePhraseologyOfferKnowMore();
   if (this.$store.state.campaign.editing.campaign.channel.technology == this.$store.state.campaign.editing.channelForm
    && this.$store.state.campaign.media) {
      let media = Object.assign({}, this.$store.state.campaign.media);
      this.media = media;
      this.media.quality = this.quality;
    } else {
      this.media = {
        quality: this.quality
      };
    }
  },
  watch: {
    '$store.state.campaign.editing.offer': function (newValue) {
      this.offer = newValue;
    },
    'offer': {
      handler: function (newValue, oldValue) {
        let offer = this.offer;
        let tariff = offer.tariff[0];
        let periodicity = tariff.periodRenew + 'dia(s)';
        if (tariff.periodRenew == 7) periodicity = 'Semanal';
        if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'Mensal';
        let textPeriodicity = tariff.periodRenew + 'dia(s)';
        if (tariff.periodRenew == 7) textPeriodicity = 'semana';
        if (tariff.periodRenew == 31 || tariff.periodRenew == 30) textPeriodicity = 'mes';
        let service = this.$store.state.campaign.editing.campaign.service.name;
        this.changePhraseologyOfferKnowMore();
      }
    },
    'media': {
      handler: function(newValue, oldValue) {
        this.$store.commit('setEditingMedia', newValue);
        this.mediaIsRecorded = (!this.$store.state.campaign.editing.media.media_is_recorded == 1);
        this.knowMoreIsRecorded = (!this.$store.state.campaign.editing.media.know_more_is_recorded == 1);
      },
      deep: true
    },
    'mediaIsRecorded': function (newValue) {
      this.media.media_is_recorded = newValue ? 0 : 1;
      this.$store.commit('setEditingMedia', this.media);
    },
    'knowMoreIsRecorded': function (newValue) {
      this.media.know_more_is_recorded = newValue ? 0 : 1;
      this.$store.commit('setEditingMedia', this.media);
    },
    'channel': function (newValue) {
      if (newValue.id != this.$store.state.campaign.media.channel_id) {
        this.media = {};
        this.media.quality = this.quality;
      } else {
        let media = Object.assign({}, this.$store.state.campaign.media);
        this.media = media;
        this.media.quality = this.quality;
      }
    }
  },
  data() {
    return {
      mediaIsRecorded: '',
      knowMoreIsRecorded: '',
      media: {},
      offer: this.$store.state.campaign.editing.offer,
      quality: '',
      quality2: '',
      serviceName: this.$store.state.campaign.editing.campaign.service.name,
      inputDisable: !this.$authorization.can('save-campaign'),
    }
  },
  methods: {
    setIvrFiles () {
      let know_more_file = this.$refs.know_more_file.files[0];
      let media_file = this.$refs.media_message_file.files[0];
      let ivrFiles = {
        know_more_file,
        media_file
      }
      this.$store.commit('appendFiles', ivrFiles)
    },
    changePhraseologyOfferKnowMore() {
      let offer = this.offer;
      let tariff = offer.tariff[0];
      let periodicity = tariff.periodRenew + 'dia(s)';
      if (tariff.periodRenew == 7) periodicity = 'Semanal';
      if (tariff.periodRenew == 31 || tariff.periodRenew == 30) periodicity = 'Mensal';
      let service = this.$store.state.campaign.editing.campaign.service.name;
      let textPeriodicity = tariff.periodRenew + 'dia(s)';
      if (tariff.periodRenew == 7) textPeriodicity = 'semana';
      if (tariff.periodRenew == 31 || tariff.periodRenew == 30) textPeriodicity = 'mes';

      let mediaMessage = this.media.media_message ? this.media.media_message + '. ' : '';
      let knowMoreMessage = this.media.know_more_message ? this.media.know_more_message + '. ' : '';
      
      if (this.$store.state.campaign.editing.channel.name == "Transferência de Chamada") {
        if(offer.trialDays > 0) {
          this.quality = `${mediaMessage}Serviço gratuito por ${tariff.periodRenew} dias. Após, será cobrado R$${Support.decimalToFixed(tariff.price)} por ${textPeriodicity}. Para contratar AGORA o ${this.serviceName} digite 0 ou digite 9 para mais informações.`;
          this.quality2 = `${knowMoreMessage}Serviço gratuito por ${tariff.periodRenew} dias. Após, será cobrado R$XX,XX por ${textPeriodicity}. Para confirmar a contratação do ${this.serviceName} digite 0 AGORA.`;
        } else {
          this.quality = `${mediaMessage}Serviço cobrado por ${textPeriodicity} no valor de R$${Support.decimalToFixed(tariff.price)}. Para contratar AGORA o ${this.serviceName} digite 0 ou digite 9 para mais informações.`;
          this.quality2 = `${knowMoreMessage}Serviço cobrado por ${textPeriodicity} no valor de R$${Support.decimalToFixed(tariff.price)}. Para confirmar a contratação do ${this.serviceName} digite 0 AGORA.`;

        }
      } else{
        if(offer.trialDays > 0) {
          this.quality = `${mediaMessage}Serviço gratuito por ${tariff.periodRenew} dias. Após, será cobrado R$XX,XX por ${textPeriodicity}. Para contratar ${this.serviceName} AGORA digite 0, para mais informações digite 9 ou estrela para voltar ao menu inicial.`;
          this.quality2 = `${knowMoreMessage}Serviço gratuito por ${tariff.periodRenew} dias. Após, será cobrado R$XX,XX por ${textPeriodicity}. Para confirmar a contratação do ${this.serviceName} digite 0 AGORA ou estrela para menu inicial.`;
        } else {
          this.quality = `${mediaMessage}Serviço cobrado por ${textPeriodicity} no valor de R$${Support.decimalToFixed(tariff.price)}. Para contratar o ${this.serviceName} AGORA digite 0, para mais informações digite 9 ou estrela para voltar ao menu inicial.`;
          this.quality2 = `${knowMoreMessage}Serviço cobrado por ${textPeriodicity} no valor de R$${Support.decimalToFixed(tariff.price)}. Para confirmar a contratação do ${this.serviceName} digite 0 AGORA ou estrela para menu inicial.`;
        }
      }
      
      this.media.quality = this.quality;
    },
  }
}
</script>
<style>
  .mt-10 {
    margin-top: 10px;
  }
</style>