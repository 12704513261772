<template>
    <div class="row">
        <div class="col-12">
            <u-portlet :title="channel.name">
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label class="form-control-label">Público Alvo</label>
                        <select :disabled="inputDisable" class="form-control">
                            <option>Duplo Opt In</option>
                            <option>Saldo Zerado</option>
                        </select>
                    </div>
                </div>

                <h5 class="mt-5">Mídia</h5>
                <div class="m-separator"></div>

                <div class="m-accordion m-accordion--default m-accordion--solid" id="channel-accordion" role="tablist">
                    <!--begin::Item-->
                    <div class="m-accordion__item">
                        <div class="m-accordion__item-head" role="tab" id="channel-accordion_item_1_head" data-toggle="collapse" href="#channel-accordion_item_1_body" aria-expanded="true">
                            <span class="m-accordion__item-title">Mensagem</span>
                            <span class="m-accordion__item-mode"></span>
                        </div>
                        <div class="m-accordion__item-body collapse" id="channel-accordion_item_1_body" role="tabpanel" aria-labelledby="channel-accordion_item_1_head" data-parent="#channel-accordion" style="">
                            <div class="m-accordion__item-content">
                                <div class="form-group">
                                    <textarea :disabled="inputDisable" class="form-control" rows="5" placeholder="Digite a sua mensagem da campanha aqui..."></textarea>
                                </div>
                                <div class="form-group">
                                    <div class="switch">
                                        <switches :disabled="inputDisable" v-model="isRecorded" color="green"></switches>
                                        <div class="switch-label">
                                            Solicitar gravação de audio
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" v-show="!isRecorded">
                                    <label class="form-control-label">Selecione um arquivo de audio</label>
                                    <input :disabled="inputDisable" type="file" class="form-control"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Item-->

                    <!--begin::Item-->
                    <div class="m-accordion__item">
                        <div class="m-accordion__item-head" role="tab" id="channel-accordion_item_2_head" data-toggle="collapse" href="#channel-accordion_item_2_body" aria-expanded="false">
                            <span class="m-accordion__item-title">Saiba Mais</span>
                            <span class="m-accordion__item-mode"></span>
                        </div>
                        <div class="m-accordion__item-body collapse hide" id="channel-accordion_item_2_body" role="tabpanel" aria-labelledby="channel-accordion_item_2_head" data-parent="#channel-accordion" style="">
                            <div class="m-accordion__item-content">
                                <div class="form-group">
                                    <textarea :disabled="inputDisable" class="form-control" rows="5" placeholder="Digite a sua mensagem da campanha aqui..."></textarea>
                                </div>
                                <div class="form-group">
                                    <div class="switch">
                                        <switches :disabled="inputDisable" v-model="isRecorded" color="green"></switches>
                                        <div class="switch-label">
                                            Solicitar gravação de audio
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" v-show="!isRecorded">
                                    <label class="form-control-label">Selecione um arquivo de audio</label>
                                    <input :disabled="inputDisable" type="file" class="form-control"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Item-->
                </div>
            </u-portlet>
        </div>
    </div>
</template>

<script>
import Switches from 'vue-switches';
import UPortlet from '@/components/UPortlet';

export default {
  name: 'NODATA',
  components: {
    Switches,
    UPortlet
  },
  created() {
    
  },
  computed: {
    channel() {
      return this.$store.state.campaign.editing.channel;
    }
  },
  data() {
    return {
			isRecorded: false,
			inputDisable: !this.$authorization.can('save-campaign'),
    }
  }
}
</script>