<template>
    <div>
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">Campanhas</h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link :to="{name: 'home'}" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">-</li>
                        <li class="m-nav__item">
                            <router-link :to="{name: 'campaign'}" class="m-nav__link">
                                <span class="m-nav__link-text">Gestão de Campanhas</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div>
                    <button v-if="$authorization.can('create-campaign')" data-toggle="modal" data-target="#campaign-store" class="btn btn-outline-danger m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill" type="button">
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="m-content">
          <div class="row">
            <div class="col-12">
              <div class="m-portlet m-portlet--full-height">
                <div class="m-portlet__body">
                  <div class="form-group m-form__group row border-bottom pb-4 mb-5">
                    <div class="col-lg-3">
                      <v-select
                        :onChange="(value => filtersChange(value, 'name'))"
                        placeholder="Nome"
                        :clearSearchOnSelect="false"
                        :options="filters.names"
                      >
                        <span slot="no-options">Nenhum resultado.</span>
                      </v-select>
                    </div>

                    <div class="col-lg-3">
                      <v-select
                        :onChange="(value => filtersChange(value, 'services'))"
                        placeholder="Serviço"
                        :clearSearchOnSelect="false"
                        :options="filters.services"
                      >
                        <span slot="no-options">Nenhum resultado.</span>
                      </v-select>
                    </div>

                    <div class="col-lg-2">
                      <v-select
                        :onChange="(value => filtersChange(value, 'channels'))"
                        placeholder="Canal"
                        :clearSearchOnSelect="false"
                        :options="filters.channels"
                      >
                        <span slot="no-options">Nenhum resultado.</span>
                      </v-select>
                    </div>

                    <div class="col-lg-2">
                      <v-select
                        :onChange="(value => filtersChange(value, 'providers'))"
                        placeholder="Provedor"
                        :clearSearchOnSelect="false"
                        :options="filters.providers"
                      >
                        <span slot="no-options">Nenhum resultado.</span>
                      </v-select>
                    </div>

                    <div class="col-lg-2">
                      <v-select
                        :onChange="(value => filtersChange(value, 'status'))"
                        placeholder="Status"
                        :clearSearchOnSelect="false"
                        :options="filters.status"
                      >
                        <span slot="no-options">Nenhum resultado.</span>
                      </v-select>
                    </div>
                  </div>
                  <u-table :columns="['Campanha', 'Serviço', 'Canal', 'Status', 'Cadastrado em', '']">
                    <tr v-if="typeof(campaigns) == 'string'">
                      <td colspan="5" class="text-center">
                        {{ campaigns }}
                      </td>
                    </tr>
                    <tr v-if="campaigns.length > 0"
                      v-for="campaign in filteredCampaigns" :key="campaign.id"
                      >
                        <td class="pt-4 pb-4">
                            {{ campaign.name }}
                        </td>
                        <td class="pt-4 pb-4">
                            {{ campaign.service.name }}
                        </td>
                        <td class="pt-4 pb-4">
                            {{ campaign.channel.name }}
                        </td>
                        <td class="pt-4 pb-4">
                            <span class="m-badge m-badge--wide" :class="badge(campaign.status).badge">
                                {{ badge(campaign.status).status }}
                            </span>
                        </td>
                        <td class="pt-4 pb-4">
                          {{ $helper.convertDatePtBr(campaign.created_at) }}
                        </td>
                        <td class="pt-4 pb-4">
                            <router-link
                            :to="{
                              name: 'campaign.info',
                              params:{id: campaign.id}
                              }">
                              <i class="fa flaticon-cogwheel-2"></i>
                            </router-link>
                        </td>
                    </tr>
                    <tr v-if="campaigns.length == 0">
                        <td colspan="5" class="text-center">
                          Não há campanhas cadastradas
                        </td>
                    </tr>
                  </u-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <u-modal id="campaign-store" size="lg" title="Nova Campanha">
          <div slot="body">
              <div class="form-group">
                  <label class="form-control-label">Nome da Campanha</label>
                  <input type="text" v-model="campaign.name" class="form-control">
              </div>
              <div class="form-group row">
                <div class="col-lg-6">
                  <label class="form-control-label">Provedor de Conteúdo</label>
                  <select 
                    name="type" 
                    class="form-control" 
                    v-model="campaign.provider_content_id"
                    @change="getServicesByProviderContent"
                  >
                    <option
                      v-if="providersContent.length"
                      v-for="providerContent in providersContent" 
                      :key="providerContent.id"
                      :value="providerContent.id"
                    >
                      {{ providerContent.name }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-6">
                  <label class="form-control-label">Serviço</label>
                  <select class="form-control"
                    @change="setOffers"
                    v-model="campaign.service_id">
                    <option v-for="service in services" :key="service.id"
                      :value="service.id">
                      {{ service.name }}
                      </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-6">
                  <label class="form-control-label">Tipo da Campanha</label>
                  <select name="type" id="type" v-model="campaign.type" class="form-control">
                    <option value="paga">Paga</option>
                    <option value="bonificada">Bonificada</option>
                  </select>
                </div>
                <div class="col-lg-6">
                  <label class="form-control-label">Oferta</label>
                  <select v-model="campaign.service_offer_id" class="form-control">
                    <option v-for="offer in offers" :key="offer.id"
                      :value="offer.id">
                      {{ offer.name }}
                      </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                  <div class="col-lg-6">
                    <label class="form-control-label">Canal</label>
                    <select @change="setCompanyAndProvider" v-model="campaign.channel_id" class="form-control">
                      <option v-for="channel in channels" :key="channel.id" :value="channel.id">
                        {{ channel.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">
                      Provedor de Mídia
                    </label>
                    <select v-model="campaign.company_id" class="form-control">
                      <option v-for="company in companies" :key="company.id" :value="company.id">
                        {{ company.name }}
                      </option>
                    </select>
                  </div>
              </div>
          </div>
          <div slot="footer">
            <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">
              Cancelar
            </button>
            <button @click="createCampaign"
            type="button" class="btn btn-primary">
              Salvar
            </button>
          </div>
        </u-modal>
    </div>
</template>

<script>
import toastr from 'toastr';
import UModal from '@/components/UModal.vue';
import UTable from "@/components/UTable.vue";
import VSelect from 'vue-select'

export default {
  name: 'campaigns',
  components: {
    UTable,
    UModal,
    VSelect
  },
  created() {
    this.getServices();
    if (this.$authorization.can('view-all-campaigns')) {
      return this.getCampaigns();
    }
    if (this.$authorization.can('view-own-service-campaigns')) {
      return this.getOwnServiceCampaigns();
    }
    if (this.$authorization.can('view-elegible-campaigns')) {
      return this.getElegibleCampaigns();
    }
  },
  data() {
    return {
      filteredCampaigns: [],
      campaigns: 'Carregando campanhas...',
      campaign: {},
      services: [],
      offers: [],
      channels: [],
      companies: [],
      providers: [],
      providersContent: [],
      selectedFilters: {
        name: null,
        services: null,
        channels: null,
        providers: null,
        status: null
      },
      filters: {
        names: [],
        services: [],
        channels: [],
        providers: [],
        status: [
          'setup',
          'aguardando aprovação uotz',
          'aguardando aprovação claro',
          'reprovado uotz',
          'reprovado claro',
          'elegivel',
        ]
      }
    }
  },
  methods: {
    translateStatus(status) {
      switch(status) {
        case 'setup':
          return 'setup';
          break;

        case 'aguardando aprovação uotz':
          return 'pending-approval-uotz';
          break;

        case 'aguardando aprovação claro':
          return 'pending-approval-claro';
          break;

        case 'reprovado uotz':
          return 'disapproved-uotz';
          break;

        case 'reprovado claro':
          return 'disapproved-claro';
          break;

        case 'elegivel':
          return 'elegible';
          break;
      }
    },
    filtersChange(value, filter) {
      if (!value) {
        this.selectedFilters[filter] = null;
      };
      if (filter == 'status' && value) {
        value = this.translateStatus(value);
        this.selectedFilters.status = value;
      }

      this.selectedFilters[filter] = value;
      this.filterCampaign();
    },
    filterCampaign() {
      let data = {
        filters: this.selectedFilters,
        company_id: this.$store.state.auth.user.company_id
      };
      if (this.$authorization.can('view-own-service-campaigns')) {
        data.scope = 'own';
      }
      if (this.$authorization.can('view-elegible-campaigns')) {
        data.scope = 'elegible';
      }
      if (this.$authorization.can('view-all-campaigns')) {
        data.scope = 'all';
      }

      axios.post('campaign/filters', data)
        .then(response => {
          console.log(response.data);
          this.filteredCampaigns = response.data;
        })
        .catch(error => console.log(error.response));
    },
    badge(status) {
      switch(status) {
        case 'setup':
          return {badge: 'm-badge--secondary', status: 'Setup'};
          break;

        case 'pending-approval-uotz':
          return {badge: 'm-badge--info', status: 'aguardando aprovação uotz'};
          break;

        case 'pending-approval-claro':
          return {badge: 'm-badge--info', status: 'aguardando aprovação claro'};
          break;

        case 'disapproved-uotz':
          return {badge: 'm-badge--danger', status: 'reprovado uotz'};
          break;

        case 'disapproved-claro':
          return {badge: 'm-badge--danger', status: 'reprovado claro'};
          break;

        case 'elegible':
          return {badge: 'm-badge--success', status: 'elegível'};
          break;

        default:
          return {badge: 'm-badge--danger', status: 'desconhecido'};
          break;
      }
    },
    getCampaigns() {
      axios.get('/campaign')
        .then(response => {
          this.campaigns = response.data.campaigns;
          this.filteredCampaigns = response.data.campaigns;
          this.providersContent = response.data.providersContent;
        })
        .catch(error => console.error(error.response));
    },
    getElegibleCampaigns() {
      axios.get('/campaign/elegible')
        .then(response => {
          this.campaigns = response.data
          this.filteredCampaigns = response.data;
          })
        .catch(error => console.error(error.response));
    },
    getOwnServiceCampaigns() {
      axios.get('/campaign/own-service/' + this.$store.state.auth.user.company_id)
        .then(response => {
          this.campaigns = response.data
          this.filteredCampaigns = response.data;
          })
        .catch(error => console.error(error.response));
    },
    getServices() {
      axios.get('/campaign/get-creation-data')
        .then(response => {
          this.services = response.data.services;
          this.channels = response.data.channels;
          this.providers = response.data.providers;
          this.fillFilters();

        })
        .catch(error => console.error(error))
    },
    getServicesByProviderContent() {
      if (this.campaign && this.campaign.provider_content_id) {
        axios.get(`/campaign/get-services-provider-content/${this.campaign.provider_content_id}`)
        .then(response => {
          this.services = response.data;
          this.fillFilters();
        })
        .catch(error => console.error(error))
      }
    },
    fillFilters() {
      if (Array.isArray(this.campaigns)) {
        this.campaigns
        .forEach(campaign => this.filters
          .names
          .push(campaign.name));
      

      if (Array.isArray(this.services)) {
        this.services
        .forEach(service => this.filters
          .services
          .push(service.name));
      }

      if (Array.isArray(this.channels)) {
        this.channels
        .forEach(channel => this.filters
          .channels
          .push(channel.name));
      }

      if (Array.isArray(this.providers)) {
        this.providers
        .forEach(provider => this.filters
          .providers
          .push(provider.name));
      }
      }
    },
    setOffers(event) {
      let service = this.services
        .find(service => service.id == event.target.value);
      this.offers = service.offers.filter(offer => offer.campaign_id == null);
    },
    setCompanyAndProvider(event) {
      if (this.campaign.company_id) this.campaign.company_id = '';
      this.companies = this.channels
        .find(mediaChannel => mediaChannel.id == event.target.value)
        .company_media_channels;
    },
    createCampaign() {
      this.campaign.user_id = this.$store.state.auth.user.id;
      axios.post('/campaign', this.campaign)
        .then(response => {
          this.campaign = {};
          swal('Sucesso!', 'Campanha cadastrada com sucesso!', 'success')
            .then(swal => {
              this.$store.commit('setCampaign', response.data);
              this.$router.push({
                name: 'campaign.info',
                params: {id: response.data.id}
              });
              $('#campaign-store').modal('hide');
            });
        })
        .catch((error) => {
          console.error(error.response);
          swal('Ops!', this.$helper.formRequestErrorsMessage(error), 'error');
        })
    }
  }
}
</script>